.navbar-div {
    height: 90px;
    padding-right: 13%;
    top: 0;
    right: 0;
    position: fixed;
    left: 441px;
    z-index: 100;
}

    .navbar-div.absolute {
        z-index: 1;
    }

html:not([data-scroll="0"]) .navbar-div {
    background: rgba(245, 245, 245, 0.8);
}

.navbar-maximize-index {
    z-index: 1;
}

.navbar-icons-div {
    display: flex;
    float: right;
}

.navbar-icons-single {
    width: 40px;
    display: block;
    padding-top: 36px;
    text-align: center;
    margin-left: 12px;
}

.navbar-icons-single-active {
    width: 40px;
    display: block;
    padding-top: 32px;
    text-align: center;
    border-top: 3px solid #ff5800;
    margin-left: 12px;
}

.burguer-menu {
    display: none;
}

.nav-logo {
    display: none;
}

.drawer-wrapper {
    width: 110px;
}

.nav-links {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    float: right;
    list-style: none;
}

.active {
    color: red;
}

.drawerStyle {
    box-shadow: none !important;
    background: #ffffff !important;
    width: 400px !important;
}

.overlayStyle {
    background: rgba(255, 255, 255, 0.5) !important;
}

.name {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #6d6e71;
    align-self: center;
    padding-top: 36px;
}

.profile {
    cursor: pointer;
}

.sidedrawer {
    height: 100%;
    top: 0;
    /*right: 0;*/
    left: auto;
    width: 360px;
    padding: 30px 15px;
    min-height: 600px;
}

.dadosconta-button {
    border: 2px solid #ff5800;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 6px 29px;
    font-family: rawline;
    font-style: normal;
    font-weight: 800; /* 700 */
    font-size: 14px;
    color: #ff5800;
    background: white;
    width: 88%; /* 206px */
    display: flex;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

    .dadosconta-button div {
        align-self: center;
        width: 80%;
        display: flex; /* ADD */
    }

    .dadosconta-button img {
        margin-left: 21px;
    }


.adicionaassociadomenor {
    /*border: 2px solid #ff5800;*/
    box-sizing: border-box;
    /*border-radius: 50px;*/
    border-top: 2px solid #E5E5E5;
    border-bottom: 2px solid #E5E5E5;
    padding: 6px 29px;
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: #ff5800;
    background: white;
    /*width: 206px;*/
    display: flex;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 65px;
    /*position: absolute;*/
}


.header-bar {
    display: flex;
    float: right;
    cursor: pointer;
}

    .header-bar img {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        margin-left: 22%;
    }

.logout-name {
    font-family: rawline;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    color: #6d6e71;
    white-space: nowrap;
}

.dadosconta {
    text-align: center;
    padding-top: 60px;
}

    .dadosconta img {
        align-self: center;
    }

.username {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ff5800;
    margin-top: 15px;
}

.quotaassociativa-box {
    padding-left: 15%;
}

.quotaassociativa {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #6d6e71;
}

.status {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #9dce5e;
}

.download-sidedrawer-div {
    position: absolute;
    bottom: 0;
    margin-bottom: 35px;
    width: 85%;
}

.downloads-sidedrawer-text {
    border-bottom: 1px solid #dddddd;
    height: 35px;
    font-family: rawline;
    font-weight: bold;
    color: #373737;
    font-size: 12px;
}
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                              Small Desktop                                                                               *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
Screen = B/w 1025px to 1290px
*/
@media (min-width: 1025px) and (max-width: 1290px) {
    .navbar-div {
        left: 240px;
    }
}

/*Small Desktop end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/*Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
    .navbar-div {
        padding-right: 50px;
        left: 80px;
    }
}

/*Necessario porque no tamanho de 768 at� 880 n�o h� espa�o para tudo o que existe nos ecr�s*/

@media (min-width: 768px) and (max-width: 880px) {
    .navbar-div {
        padding-right: 50px;
        left: 55px;
    }
}
/*Tablet end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media (max-width: 767px) {
    .navbar-div {
        background: white;
        height: 65px;
        padding-top: 0px;
        padding-top: 11px;
        padding-right: 21px;
        align-items: center;
        left: 0;
        z-index: 100;
    }

        .navbar-div.absolute {
            position: absolute;
        }

    .nav-links {
        margin: 0px;
    }

    .navbar-div .name {
        display: none;
    }

    .navbar-icons-single {
        width: 40px;
        display: block;
        padding-top: 9px;
        text-align: center;
        margin-left: 6px;
    }

    .navbar-icons-single-active {
        width: 40px;
        display: block;
        padding-top: 9px;
        text-align: center;
        border-top: 0px solid #ff5800;
        margin-left: 6px;
    }

    .burguer-menu {
        display: block;
        padding-top: 9px;
        cursor: pointer;
        margin-left: 17px;
    }

    .burguer-menu-icon {
        width: 26px;
    }

    .nav-logo {
        display: inline;
        padding-left: 31px;
    }

    .downloads-wrapper {
        position: absolute;
        bottom: 0px;
        margin-bottom: 35px;
        width: 85%;
        margin-top: 25px;
    }
}

@media (max-height: 550px) {
    .download-sidedrawer-div {
        position: fixed;
        bottom: unset;
        margin-top: 35px;
        width: 85%;
    }
}

/*Utilizado vara verificar a altura do ecra*/
@media (max-width: 767px) and (max-height: 650px) {
    .sidedrawer {
        padding: 30px 15px;
        width: 247px;
    }
}

/*Smartphone end*/
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                            SideDrawer Height                                                                             *
 *                                                                                                                                                                          *
/***************************************************************************************************************************************************************************/

@media (min-height: 570px) {
    .downloads-wrapper {
        position: relative;
        bottom: 0px;
        margin-bottom: 35px;
        width: 85%;
        margin-top: 25px;
    }
}

@media (max-height: 569px) {
    .downloads-wrapper {
        position: relative;
        margin-top: 25px;
    }
}

/*SideDrawer Height end*/
