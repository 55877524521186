.popup-campanha {
    height: auto;
    margin: auto;
    position: relative;
    max-width: 600px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-content {
    display: flex;
    align-items: stretch;
    max-height: fit-content;
}

.popup-image {
    width: 40%;
    position: relative;
}

    .popup-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

.wrap-popup-btt {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.popup-campanha-text {
    text-align: left;
    font-weight: normal;
}

    .popup-campanha-text h2 {
        color: #ff5800;
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 16px;
        line-height: 1.1;
    }

    .popup-campanha-text p {
        font-size: 14px;
        line-height: 1.4;
        color: #333;
        margin-bottom: 16px;
    }

.highlight {
    color: #ff5800;
    font-weight: 500;
}

.terms-link {
    color: #ff5800;
    text-decoration: none;
}

.popup-campanha-fechar-center {
    cursor: pointer;
    background-color: #ff5800;
    color: white;
    border: none;
    border-radius: 9999px;
    padding: 10px 32px;
    font-size: 14px;
    font-weight: 500;
    width: auto;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .popup-content {
        flex-direction: column;
    }

    .popup-image {
        width: 100%;
        height: 200px;
    }

        .popup-image img {
            position: static;
        }

    .wrap-popup-btt {
        width: 100%;
    }

    .popup-campanha-text h2 {
        font-size: 28px;
    }

    .popup-campanha-text p {
        font-size: 13px;
    }

    .popup-campanha-fechar-center {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .popup-campanha-text h2 {
        font-size: 24px;
    }

    .popup-campanha-text p {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    /* ... existing mobile styles ... */

    .popup-campanha-fechar-center {
        width: 100%;
        max-width: 143px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

