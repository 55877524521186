.associate-div {
    background: #FFFFFF;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    height: 100%;
    width: 754px;
    padding: 15px;
    margin-top: 60px;
}

/*--------------------Card section-------------------------------*/
.card-section {
    background: #F5F5F5;
    border-radius: 30px;
    padding: 21px 55px;
    display: flex
}

/*Card section end*/

/*--------------------Advantages Section-------------------------*/
.advantages-section {
    margin: 30px 53px 40px 53px;
    border-bottom: 1px solid #DDDDDD;
}

.advantages-div-1 {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 150%;
    color: #6D6E71;
    text-align: center;
    margin-bottom: 40px;
}

.advantages-div-2 {
    text-align: center;
    margin-bottom: 42px
}

    .advantages-div-2 .div-2-title {
        font-family: rawline;
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #373737;
        width: 360px;
    }

    .advantages-div-2 .div-2-text {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #373737;
        width: 360px;
        margin: auto;
    }

.advantages-div-3 {
    display: flex;
    margin-bottom: 40px;
}

    .advantages-div-3 img {
        margin-bottom: 30px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .advantages-div-3 p {
        text-align: center;
        font-family: Open Sans;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        color: #373737;
        padding: 10px;
    }

/*Advantages Section end*/

/*--------------------Information Section-------------------------*/
.information-section {
    margin: 30px 53px 40px 53px;
}
/*Information Section end*/

/*--------------------Downloads Section-------------------------*/
.downloads-section {
    margin: 55px 53px 40px 53px;
}

.downloads-section-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    color: #373737;
    height: 40px;
    border-bottom: 1px solid #DDDDDD;
}
/*Downloads Section end*/
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
/*Necessario porque no tamanho de 768 até 880 não há espaço para tudo o que existe nos ecrãs*/
@media(min-width:768px) and (max-width:880px) {
    .associate-div {
        width: 100%;
    }
}

/*Tablet end*/
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:767px) {
    .advantages-div-2 .div-2-text {
        width: 100%;
    }

    .information-section {
        margin: 30px 0px 40px 0px;
    }

    .mobile-associate-title {
        margin-left: 10px;
    }

    .card-section img {
        margin-left: auto;
        margin-right: auto;
        display: table;
    }

    .associate-div {
        width: 100%;
        margin: 40px 0 0 0;
        border-radius: 0;
    }

    .card-section {
        display: inherit;
        margin: -15px;
        border-radius: 0px;
    }

    .advantages-div-3 {
        display: inherit;
    }
}

/* 
Device = Smartphones
Screen = B/w until 500px
*/
@media(max-width:500px) {
    .card-section img {
        width: 100%;
        height: 100%;
    }

    .card-section .down-group img {
        width: 50px;
        height: 50px;
    }
}
/*Smartphone end*/
