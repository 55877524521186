

.glossary-close {
    float: right;
    margin-right: 50px;
    margin-top: 50px;
    cursor: pointer;
}


.help-window {
    position: fixed;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 748px;
    background: #FFFFFF;
    box-shadow: 0px 3px 25px rgba(54, 54, 54, 0.1);
    overflow: scroll;
}

.glossary-content {
    margin-left: 93px;
    margin-right: 93px;
    margin-top: 130px;
    margin-bottom: 130px;
}

.help-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: #373737;
    margin-bottom: 23px;
}

.help-subtitle {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #373737;
    margin-bottom: 9px;
}

.help-text {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    color: #6D6E71;
    margin-bottom: 40px;
}

.ContentFromWordPress {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    color: #6D6E71;
    margin-bottom: 40px;
}

.ContentFromWordPress strong {
        font-family: rawline;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: #373737;
        margin-bottom: 9px;
    }



/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:767px) {
    .contact-close{
        display: none;
    }

    .help-window-contact{
        position: initial;
        transform: translateX(0);
    }

    .glossary-close {
        margin-right: 75px;
    }

    .glossary-content {
        margin-left: 20px;
        margin-right: 20px;
    }
}
/*Smartphone end

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  CurrentAccount Pop Up                                                                   *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************/


.sub-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: #FF5800;
    margin-bottom: 23px;
    line-height: 36px;
}

.note-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #373737;
    margin-bottom: 23px;
    line-height: 19.6px;
}


.sub-title_mobile {
    font-family: rawline;
    font-weight: 700;
    font-size: 18px;
    line-height: 25.2px;
    color: #FF5800;
}

.note-title_mobile {
    font-family: rawline;
    font-weight: 400;
    font-size: 12px;
    color: #373737;
    margin-bottom: 23px;
    line-height: 18px;
}
