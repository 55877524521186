.sidebar {
    height: 100%;
    background: white;
    position: fixed;
    top: 0%;
    padding-top: 10px;
    padding-bottom: 47px;
    padding-left: 127px;
    width: 441px;
    overflow-y: auto;
    -webkit-overflow-y: auto;
    /*hide scroll bar*/
    -ms-overflow-style: none;
}

.sidebar .list-element-gestor .loading {
    margin-top: 0px;
}
/*hide scroll bar*/
.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar-logo {
    margin-bottom: 10%;
}

.sidebar-logo-mobile {
    display: none;
    margin-bottom: 60px;
}

.sidebar-list li {
    padding-bottom: 24px;
}

.sidebar-list ul {
    list-style-type: none;
}

.sidebar-list a:hover,
.sidebar-list a:active {
    text-decoration: none;
    color: #FF5800;
}

.poweredbydiv {
    margin-top: 20px;
}

    .poweredbydiv img {
        width: 200px;
    }

.poweredby {
    font-family: Open Sans;
    font-size: 8px;
    line-height: 150%;
    color: #B2B2B2;
}

.link {
    font-family: rawline;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #6D6F71;
    display: flex;
    flex-flow: row nowrap;
}

    .link.active {
        text-decoration: none;
        color: #FF5800;
        stroke: #FF5800;
        border-right: 4px solid #FF5800;
    }

    .list-element-gestor {
        display: none;
        cursor: pointer;
    }

.manager-box {
    margin-right: 90px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding-top: 10%;
    padding-bottom: 10%;
    align-items: center;
    text-align:center;
}

.avatar {
    display: inline-block;
    padding-left:0px;
}

.manager-name-occupation {
    list-style-position: inside;
    display: inline-block;
    width: 100%;
}

.manager-name {
    list-style-type: none;
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #FF5800;
}

.manager-occupation {
    font-family: rawline;
    list-style-type: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #595959;
    display: inline-block;
    padding-bottom: 10px;
}


.contact-popup-button {
    border: 2px solid #FF5800;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    align-items: center;
    color: #FF5800;
    height: 30px;
    width: 120px;
    background-color: white;
}

.popup-content {
    width: auto !important;
    z-index: 999!important;
    display: contents; /*Importanto to display all Popups information*/
}

.sidebar-links {
    padding: 0px;
    margin-bottom: 0px;
}

    .sidebar-links svg {
        margin-right: 10px;
        width: 30px;
    }

/*Contact popup*/
.contact-popup {
    background: #FFFFFF;
    top: 25%;
    position: relative;
    border-radius: 40px;
    box-shadow: 0px 3px 25px rgba(54, 54, 54, 0.1);
    display: flex;
}

.contact-popup-main {
    padding: 50px;
}

    .contact-popup-main img {
        margin-top: -50px;
    }

.contact-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.contact-popup-information {
    display: inline-block;
    margin-left: 30px;
    text-align: left;
    height: 100%;
    vertical-align: bottom;
}

.contact-popup-name {
    font-family: rawline;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #FF5800;
}

.contact-popup-role {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    color: #6D6F71;
}

.contact-popup-phone {
    font-family: Rawline;
    font-size: 16px;
    line-height: 140%;
    color: #6D6F71;
}

.contact-popup-email {
    font-family: rawline;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #6D6F71;
}
/*Contact popup end*/

/*No manager popup*/

.contact-no-manager-popup-main {
    padding: 75px;
}

    .contact-no-manager-popup-main img {
        margin-top: -100px;
    }

.contactnomanager-popup-name {
    font-family: rawline;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    color: #FF5800;
}

.contactnomanager-popup-role {
    font-family: rawline;
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    color: #6D6F71;
}

.contactnomanager-popup-phone {
    font-family: rawline;
    font-size: 16px;
    line-height: 140%;
    color: #6D6F71;
}

.contactnomanager-popup-phone a{
    font-family: rawline;
    font-size: 18px;
    line-height: 140%;
    color: #6D6F71;
}

/*No manager end*/
.button-cancel {
    cursor: pointer;
}

.button-continue {
    cursor: pointer;
    margin-left: 6%;
}

.manager-popup-main{
    margin-top: 30%;
    margin-left: auto;
    margin-right: auto;
    display: table;
}

.contact-confirm-popup {
    padding: 32px 63px;
    width: 100%;
    display: inline-block;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
    height: 500px;
}

.contact-tab{
    width: 80%;
    height: 80px;
    background: #FFFFFF;
    border-radius: 40px 40px 0px 0px;
    overflow: hidden;
    background-color: #f1f1f1;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    margin-top: 61px;
    margin-left: auto;
    margin-right: auto;
}

.contact-tab-div{
    width: 80%;
    margin-left:auto;
    margin-right: auto;
    height: 400px;
    background: white;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 40px 40px;
    padding-top: 63px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 42px;
}
.contact-tab-div p{
    font-family: rawline;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #FF5800;
    float: left;
}

.contact-tab-div textarea{
    height: 191px;
    width: 100%;
    resize: none;
    padding: 20px;
}

.contact-tab .Tabs__Tab {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    width: 50%;
    height: 80px;
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #595959;
    text-align: center;
    padding-top: 30px;
}

    .contact-tab .Tabs__Tab:not(.Tabs__Tab--active):hover {
        background-color: #ddd;
    }

    .contact-tab .Tabs__Tab--active {
    background-color: #ffffff;
    color: #FF5800;
    cursor: default;
}

.success-message-div {
    margin-top: 50%;
    text-align: center;
}

    .success-message-div img{
        width: 100px;
        margin-bottom: 42px;
    }

    .success-message-div p{
        font-family: rawline;
        font-size: 30px;
        font-weight: bold;
        line-height: 120%;
        color: #373737;
        margin: 10px 60px;
    }

.button-orange-filled{
    background: #FF5800;
    border-radius: 50px;
    padding: 10px 0px;
    text-align: center;
    color: white;
    width: 165px;
    font-family: rawline;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.disabled-orange-filled{
    background: #B2B2B2;
    border-radius: 50px;
    padding: 10px 0px;
    text-align: center;
    color: white;
    width: 165px;
    font-family: rawline;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
/*Medium displays Layout*/

@media(min-height:830px) {
    .sidebar-list {
        margin-top: 10%;
        margin-bottom: 10%;
    }
}

@media(min-height:780px) {
    .sidebar-logo {
        margin-bottom: 30%;
    }
}

@media(min-height:900px) {
    .poweredbydiv {
        position: absolute;
        bottom: 0;
        margin-bottom: 35px;
    }
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                              Small Desktop                                                                               *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
Screen = B/w 1025px to 1290px
*/
@media(min-width:1025px) and (max-width:1290px) {
    .sidebar {
        width: 240px;
        padding-left: 30px;
    }

    .manager-box {
        max-width: 190px;
        margin-right: 0px;
    }
}

/*Small Desktop end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/

@media(min-width:768px) and (max-width:1024px) {
    .sidebar {
        text-align: center;
        width: 80px;
        padding-left: 0px;
        padding-top: 25px;
    }

    .sidebar-links .link {
        display: block;
    }

    .sidebar-logo {
        display: none;
    }

    .sidebar-logo-mobile {
        display: inline;
    }

    .sidebar-links li a.link:not(.link-gestor) span {
        display: none;
    }

    .list-element-gestor {
        display: block;
    }

    .link.link-gestor {
        color: #6D6F72;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }

    .manager-box {
        display: none;
    }

    .poweredbydiv img {
        width: 75%;
    }
}

/*Utilizado vara verificar a altura do ecra*/
@media(min-width:768px) and (max-width:1024px) and (min-height:545px) {
    .poweredbydiv {
        position: absolute;
        bottom: 0;
        margin-bottom: 47px;
    }
}

/*Necessario porque no tamanho de 768 até 880 não há espaço para tudo o que existe nos ecrãs*/
@media(min-width:768px) and (max-width:880px) {
    .sidebar {
        width: 55px;
    }
}
/*Tablet end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
    @media(max-width:767px) {
        .manager-popup-main img{
            margin-left: auto;
            margin-right: auto;
            display: flex;
        }
        .contact-orange-button{
            width: 100%;
        }
        .contact-confirm-button{
            width: 100%;
            margin-top:72px;
        }
        .contact-tab{
            width: 100%;
            border-radius: 0px 0px 0px 0px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
        }
        .contact-tab-div{
            width: 100%;
            border-radius: 0px 0px 0px 0px;
            height: 530px;
        }
        
        .fixed-sidebar {
            display: none;
        }

        .mobile-side-bar {
            display:block
        }

        .sidebar-logo {
            display: none;
        }

        .sidebar-logo-mobile {
            display: inline;
        }

        .sidebar {
            padding-left: 25px;
            width: 100%;
        }

        /*Contact popup*/
        .contact-popup {
            position: fixed;
            width: 100vw;
            height: 100%;
            left: 0;
            top: 0;
            background: white;
            z-index: 10;
            border-radius: 0px;
            align-items: center;
        }

        .contact-popup-main,
        .contact-no-manager-popup-main {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        .contact-popup-information {
            margin-left:0px;
            display:block;
            text-align:center;
        }

        .contact-popup-phone {
            margin-top: 13px;
        }

        .manager-box {
            margin-right: 0px;
            width: 170px;
        }
    }

    /*Utilizado vara verificar a altura do ecra*/
    @media(max-width:767px) and (max-height:650px) {
        .sidebar-logo-mobile {
            margin-bottom: 20px;
        }

        .sidebar-list li {
            padding-bottom: 20px;
        }

        .sidebar-list li.list-element-gestor {
            padding-bottom: 0px;
        }

        .manager-box {
            padding:10px;
        }
    }
/*Smartphone end*/