.tabelasCandidatos {
  width: 754px;
}

.tabelaCandidatosTitulo {
  font-size: 18px;
  font-weight: bold;
  min-height: 60px;
  margin-bottom: 30px;
  font-family: rawline;
}

.position-table{
    position: inherit !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
}

.tabelaCandidatos .candidato {
    min-height: 100px;
}

.tabelaCandidatos .candidato:not(:last-child) {
  border-bottom: 1px solid #b2b2b2;
}

.tabelaCandidatos .candidato:last-child .esquerda {
  border-radius: 0px 0px 0px 30px;
}

.tabelaCandidatos .candidato:last-child .direita {
  border-radius: 0px 0px 30px 0px;
}

.tabelaCandidatos .candidato:first-child .esquerda {
  border-radius: 30px 0px 0px 0px;
}

.tabelaCandidatos .candidato:first-child .direita {
  border-radius: 0px 30px 0px 0px;
}

.col-4 {
    flex: 0 0 43% !important;
    max-width: 43% !important ;
}

.col-8 {
    flex: 0 057% !important;
    max-width: 57% !important;
}

.candidato .esquerda {
  background-color: #fff7f2;
  color: #ff5800;
  padding: 20px;
  font-weight: bold;
  font-size: 18px;
  font-family: rawline;
}

.candidato .direita {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.candidato .document-descricao {
  margin-top: 8px;
  font-family: Open Sans;
}

@media (max-width: 880px) {
  .tabelaCandidatosTitulo {
    font-size: 16px;
  }

  .tabelasCandidatos {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .tabelaCandidatosTitulo {
    min-height: unset;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .tabelasCandidatos {
    margin-left: auto;
    margin-right: auto;
  }

  .tipoEleicao {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tipoEleicao:last-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 580px) {
  .candidato .esquerda {
    padding: 10px;
  }
}
