/*Title bar*/
.title-bar {
  display: flex;
  width: 754px;
  height: 1%;
  overflow: hidden;
}

.modalidades-mobile-detalhes {
  display: none;
}

.title {
  font-family: rawline;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  color: #373737;
  width: 50%;
  float: left;
}

.title-glossary {
  width: 50%;
  float: right;
}

.popup {
  cursor: pointer;
  float: right;
}

/*Title bar end*/
.modalidades-bar {
  margin-top: 60px;
  margin-bottom: 20px;
  width: 754px;
}

.modalidades-bar-2 {
  width: 754px;
  padding-top: 40px;
}

.modalidades-div {
  display: flex;
  left: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.modalidades-div img {
  margin-right: 5%;
  margin-left: 20%;
}

.modalidades {
  margin-bottom: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  width: 754px;
  border-radius: 40px;
  background: #ffffff;
  min-height: 100px;
}

.modalidades.modalidades-mobile,
.card-new.card-new-mobile {
  display: none;
}

.modalidades-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #373737;
}

/*Table title*/
.tableCapitalCerto {
  border-collapse: collapse;
  min-height: 100px;
  width: 100%;
}

.tableCapitalCerto td,
th {
  border: none !important;
}

.tableCapitalCerto-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #ff5800;
}

.tableCapitalCerto-title-inactive {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #6d6f71;
}

.tableCapitalCerto-title-inactive-bw {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #d67823;
}

.tableCapitalCerto-titlesubtitle {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 13px;
  color: #585858;
}

.tableCapitalCerto-orangeback {
  background: rgba(255, 88, 0, 0.05);
  border-radius: 40px 0 0 0;
  width: 30%;
  height: 100%;
  padding-left: 30px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 37px;
  cursor: pointer;
}

.tableProtection-orangeback {
  min-width: 226px;
  background: rgba(255, 88, 0, 0.05);
  width: 30%;
  height: 100%;
  padding-left: 30px;
  padding-top: 16px;
  padding-right: 37px;
  cursor: default;
}

.tableCapitalCerto-th {
  width: 155px;
}

.tableCapitalCerto-givenvalue {
  font-family: rawline;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #373737;
  padding-top: 8px;
}

.tableCapitalCerto-boldvalue {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: #b2b2b2;
}

.tableCapitalCerto-boldvalue-active {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #b2b2b2;
  padding-top: 8px;
}

.tableCapitalCerto-subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #373737;
}

.tableCapitalCerto-subtitle-unactive {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #b2b2b2;
}

.tableCapitalCerto-img {
  cursor: pointer;
  float: right;
  margin-right: 52px;
}

.tableCapitalCerto-img.opened img {
  transform: rotate(180deg);
}

/*End of card title*/
.subscription-active {
  margin-top: 5px;
  margin-left: 15px;
}

.subscribe-text {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  color: #373737;
  margin-bottom: 20px;
  margin-top: 60px;
}

.new-series {
  background: #ffffff;
  border: 2px dashed #b2b2b2;
  border-radius: 30px;
  width: 100%;
  height: 86px;
  padding-left: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
}

.new-series-bw {
    background: #332A63;
    border: 4px dashed #d67823;
    border-radius: 30px;
    width: 100%;
    height: 86px;
    padding-left: 24px;
    padding-top: 14px;
    padding-bottom: 14px;
    display: flex;
}

.new-series-text {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #636363;
}

.new-series-text-bw {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #d67823;
}

.new-series-text-bw-mb {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #d67823;
    margin-top: 11px;
}

.new-series-img {
  float: right;
  margin: 0px 24px;
  cursor: pointer;
}

.right-align {
  text-align: right;
}

.resumo-poupanca {
  display: flex;
  align-items: flex-end;
}

.resumo-poupanca .first-div {
  display: flex;
  align-items: baseline;
}

.resumo-poupanca .second-div {
  display: flex;
  margin-left: auto;
  align-items: flex-end;
}

.table-header-poupanca {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 150%;
  color: #373737;
  width: 20px;
}

.width-60 {
    width: 60px!important;
}

.width-80 {
    width: 80px !important;
}

.width-70 {
    width: 70px !important;
}

.width-105 {
    width: 105px !important;
}

.table-appliedtitle {
    font-family: rawline;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #373737;
    padding-bottom: 0;
    white-space: nowrap;
}

.table-modalidades {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #373737;
}

.table-subscricoes {
  font-family: rawline;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  padding-left: 10px;
  color: #ff5800;
  width: 100%;
  padding-bottom: 0px !important;
}

.table-applied {
  font-family: rawline;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  color: #ff5800;
  width: 100%;
}

.table-capitalcerto {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  color: #ff5800;
  width: 100%;
}

.table-capitalcerto tr {
  border-collapse: collapse;
  border: none;
}

.table-deliveredvalue {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  color: #373737;
  width: 100%;
}

.table-boldvalue {
  font-family: rawline;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  display: flex;
  color: #b2b2b2;
  width: 100%;
}

.table-deliveredtitle {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 150%;
  color: #373737;
}

.table-boldtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #595959;
}

.series th {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 150%;
  color: #373737;
}

.series-title {
  background: rgba(221, 221, 221, 0.5);
}

.series-appliedammount {
  font-family: rawline;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #373737;
}

.series-date {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 150%;
  color: #373737;
}

.series-subscription {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 150%;
  color: #373737;
}

.card-new {
  background: #ffffff;
  border: 2px dashed #b2b2b2;
  border-radius: 40px;
  width: 754px;
  height: 100%;
  min-height: 100px;
  margin-bottom: 30px;
  display: flex;
  overflow: hidden;
}

.card-new-bw {
    background: #332A63;
    border: 4px dashed #d67823;
    border-radius: 40px;
    width: 754px;
    height: 100%;
    min-height: 100px;
    margin-bottom: 30px;
    display: flex;
    overflow: hidden;
}


.card-new-title {
  font-family: rawline;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  height: 100px;
  width: 195px;
  padding-right: 15px;
  padding-left: 30px;
  padding-top: 31px;
  padding-bottom: 31px;
  background: rgba(109, 110, 113, 0.05);
  color: #6d6f71;
  cursor: default;
}

.card-new-table {
  display: inline-block;
}

.card-new-table-value {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #b2b2b2;
}

.card-new-table-title {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 150%;
  color: #b2b2b2;
}

.card-new img {
  margin-left: 18%;
  margin-top: 8px;
}

.SavingsItems-value {
  font-family: rawline;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #373737;
}

.SavingsItems-date {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 150%;
  color: #373737;
}

.SavingsItems-number {
  font-family: Open Sans;
  font-size: 12px;
  line-height: 150%;
  color: #373737;
  padding-top: 6px;
}

.arrow-up {
  width: 100%;
}

.arrow-up img {
  float: right;
  margin-right: 52px;
  margin-top: -5rem;
}

.arrow-up-protect {
  width: 100%;
}

.arrow-up-protect img {
  float: right;
  margin-right: 52px;
  margin-top: -67px;
}

.create-subscription-button {
  margin-left: 100px;
}

.resumo-poupanca .third-div {
  display: none;
}

.modalidades-bar.modalidades-bar-mobile {
  display: none;
}

.header-with-margin{
    margin-right: 8px;
}

#createSubscriptionMobile {
    display: flex;
    padding-top: 1rem;
    justify-content: center;
    cursor: pointer;
}



#createSubscriptionMobile img {
  width: 1.3rem;
  margin-right: 0.5rem;
 }


.createSubscriptionMobile-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #ff5800;
}

#saving-create-subscription-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

#saving-create-subscription-button img {
  width: 1.3rem;
  margin-right: 0.5rem;
  margin-top: 0;
  margin-left: 0;
 }

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
/*Necessario porque no tamanho de 768 at� 880 n�o h� espa�o para tudo o que existe nos ecr�s*/
@media (min-width: 768px) and (max-width: 880px) {
    .title-bar, .modalidades-bar, .subscribe-text, .card-new, .card-new-bw, .sub-title-bar {
        width: 100% !important;
    }

  .create-subscription-button {
    margin-left: 15px;
  }

  .modalidades {
    width: 100%;
  }

  .resumo-poupanca .first-div {
    display: block;
  }

  .table-subscricoes {
    padding-left: 0px;
  }

    .reaplicar-reforcar-buttons{
        display: inline-block!important;
    }

    .reaplicar-button, .reforcar-button {
        margin-right: 0px !important;
        margin-bottom: 5px !important;
    }
}

/*Tablet end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media (max-width: 767px) {
    .title-bar {
        width: 100%;
    }

    .sub-title-bar {
        width: 100% !important;
    }

    .modalidades-bar {
        width: 100%;
        margin-top: 35px;
    }

    .resumo-poupanca {
        display: grid;
        margin-bottom: 18px;
    }

        .resumo-poupanca .first-div {
            display: none;
        }

        .resumo-poupanca .second-div {
            margin-right: auto;
        }

        .resumo-poupanca .third-div {
            margin-top: 40px;
            display: block;
        }

            .resumo-poupanca .third-div .table-subscricoes {
                padding-left: 0px;
            }

    .modalidades,
    .card-new, .card-new-bw {
        display: none;
    }

    .tableCapitalCerto-title-inactive-bw{
        display:none;
    }

    .modalidades.modalidades-mobile {
        display: block;
        width: 207px;
        text-align: left;
        box-shadow: unset;
    }

        .modalidades.modalidades-mobile .tableCapitalCerto-orangeback,
        .card-new.card-new-mobile .card-new-title {
            padding: 20px;
            width: 100%;
            cursor: default;
        }

            .modalidades.modalidades-mobile .tableCapitalCerto-givenvalue,
            .modalidades.modalidades-mobile .tableCapitalCerto-boldvalue-active {
                padding-top: 0px;
            }

            .modalidades.modalidades-mobile .tableCapitalCerto-subtitle {
                padding-top: 5px;
            }

        .modalidades.modalidades-mobile .tableCapitalCerto-amount,
        .card-new.card-new-mobile .tableCapitalCerto-amount {
            padding: 20px 20px 0px 20px;
        }

            .modalidades.modalidades-mobile .consultar-detalhes {
                text-align: center;
                padding: 20px;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                align-items: center;
                text-decoration-line: underline;
                color: #6d6e71;
                cursor: pointer;
            }

    .modalidades-mobile-detalhes {
        display: block;
        overflow: scroll;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: white;
        z-index: 10;
        padding-bottom: 20px;
    }

        .modalidades-mobile-detalhes .title-section {
            background: #fff7f2;
            padding: 25px 20px;
        }

            .modalidades-mobile-detalhes .title-section .title-main {
                color: #ff5800;
                font-size: 25px;
                font-family: Raleway;
                font-weight: bold;
                display: flex;
                align-items: center;
            }

            .modalidades-mobile-detalhes .title-section .title-subtitle {
                font-family: Raleway;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: #b2b2b2;
            }

            .modalidades-mobile-detalhes .title-section .title-main img {
                cursor: pointer;
                margin-left: auto;
                width: 50px;
                height: 50px;
            }

        .modalidades-mobile-detalhes .montantes-section {
            display: flex;
            padding: 20px 20px;
        }

            .modalidades-mobile-detalhes .montantes-section .tableCapitalCerto-amount {
                width: 50%;
            }

        .modalidades-mobile-detalhes .subscricao-detalhe {
            display: flex;
            margin-bottom: 15px;
        }

            .modalidades-mobile-detalhes .subscricao-detalhe:last-of-type {
                margin-bottom: 0px;
            }

            .modalidades-mobile-detalhes .subscricao-detalhe .label {
                width: 70%;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 150%;
                color: #b2b2b2;
            }

            .modalidades-mobile-detalhes .subscricao-detalhe .value {
                width: 30%;
                font-family: Raleway;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 160%;
                color: #373737;
            }

                .modalidades-mobile-detalhes .subscricao-detalhe .value.acumulado {
                    color: #636363;
                }

                .modalidades-mobile-detalhes .subscricao-detalhe .value.data {
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    color: #373737;
                }

        .modalidades-mobile-detalhes .serie-mobile {
            padding: 20px 0px;
            border-bottom: 1px solid #dddddd;
        }

        .modalidades-mobile-detalhes .serie-mobile-first-div {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
        }

            .modalidades-mobile-detalhes .serie-mobile-first-div img {
                margin-left: auto;
            }

        .modalidades-mobile-detalhes .serie-mobile-wrapper {
            padding: 0px 20px;
        }

        .modalidades-mobile-detalhes .new-serie-wrapper {
            padding: 0px 20px;
            margin-top: 20px;
        }

    .card-new.card-new-mobile {
        display: block;
        width: 207px;
        text-align: left;
        box-shadow: unset;
        margin-left: auto;
        margin-right: auto;
        height: unset;
    }

    .card-mobile-bw {
        border: 4px dashed #d67823 !important;
        background: #332A63 !important;
    }

    .card-new-title {
        color: #d67823 !important;
        font-weight: bold;
        padding: 12px !important;
    }

    .subscribe-text {
        width: 100% !important;
    }

    .card-new.card-new-mobile .create-subscription-button {
        margin-top: 30px;
        float: right;
        margin-right: 25px;
        margin-bottom: 25px;
    }

        .card-new.card-new-mobile .create-subscription-button .card-new img {
            margin: 0px;
        }

    .carousel-wrapper.carousel-subscricao-nova-modalidade {
        margin-top: 0px;
    }

    .modalidades-mobile-detalhes .new-series {
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .help-window {
        width: 100%;
    }

    .modalidades-bar {
        display: none;
    }

        .modalidades-bar.modalidades-bar-mobile {
            display: block;
        }

    .modalidades-bar-protecao {
        display: block;
    }

    .reaplicar-reforcar-buttons-mobile {      
        margin-left: auto;
     display: inherit;
   }
}
/*Smartphone end*/
