.popup-window {
    position: fixed;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 80%;
    max-width: 1000px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 3px 25px rgba(54, 54, 54, 0.1);
    overflow: auto;
    cursor: auto;
}

.download-container {
    width: 33%;
}

.imgcenter {
    width: 20%;
    max-width: 30px;
}

.download-container-text {
    width: 80%;
}

.popup-window-close {
    left: 100%;
    right: -100%;
    top: 0%;
    bottom: 0%;
    text-align: right;
    margin-bottom: 20px;
}

    .popup-window-close img {
        left: 100%;
        right: -100%;
        top: 0%;
        bottom: 0%;
        height: 50px;
    }

.title-productdetails {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #212529;
}

.additional-info img {
    /*max-width: 100%;*/
    max-height: 100%;
    margin-top: 20px;
    margin-left: 0px;
}

.popup-window .subtitle {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #6D6E71;
    margin-bottom: 20px;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
}


.popup-window .central-container {
    background: #FF5800;
    border-radius: 69px;
    min-height: 150px;
    margin-top: 50px;
    margin-bottom: 0px;
    color: white;
}

    .popup-window .central-container .image-container {
        background-size: auto 100%;
        background-repeat: no-repeat;
        border-radius: 68px 0px 0px 68px;
    }

.central-container .title-productdetails {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 5px 10px 5px 10px;
}

.popup-window .central-container .details {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    padding: 5px 10px 5px 10px;
    margin-top: 30px;
    width: 85%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

    .popup-window .central-container .details div {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .popup-window .central-container .details .text1 {
        font-family: rawline;
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }

    .popup-window .central-container .details .text2 {
        font-family: rawline;
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }

    .popup-window .central-container .details .text3 {
        font-family: rawline;
        font-weight: bold;
        font-size: 12px;
        line-height: 140%;
        /* or 17px */
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }


.ticketIcon {
    background: url(../../images/ticket.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 60px;
    width: 60px;
    margin: auto;
}

.benefitsIcon {
    background: url(../../images/Beneficios.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 60px;
    width: 60px;
    margin: auto;
}

.documentIcon {
    background: url(../../images/document.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 26px;
    margin: auto;
}

.dowloadIcon {
    background: url(../../images/download.png);
    background-size: auto 80%;
    background-repeat: no-repeat;
    width: 17px;
    padding: 7px;
    display: table-cell;
}



.popup-window .central-container .description {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding-left: 10px;
    padding-right: 10px;
}

.additional-info {
    margin-top: 60px;
    margin-bottom: 10px;
    padding: 0px 0px 0 30px;
    display: flex;
}

    .additional-info .title-productdetails {
        font-family: rawline;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        align-items: center;
        color: #373737;
        margin-top: 0px;
        margin-bottom: 15px;
        padding-left: 0px;
        padding-right: 0px
    }

    .additional-info .description-productdetails {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 150%;
        color: #6D6F71;
        padding-left: 0px;
        padding-right: 0px;
    }

    .additional-info .image-productdetails {
        padding-left: 0px;
        padding-right: 0px;
        height: 100px;
        width: 100px;
        /*overflow: hidden;*/
        text-align: center;
    }

        .additional-info .image-productdetails img {
            /*width: 100px;*/
            margin-top: 0px;
            margin-left: 0px;
        }

    .additional-info .text-productdetails {
        margin-left: 30px;
        max-width: 754px;
    }


.download-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    color: #373737;
    border-bottom: 1px solid #DDDDDD;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 30px;
    padding-bottom: 15px;
    padding-left: 0px;
}

.download-container .title {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #373737;
    padding: 0px;
    margin: 0px;
}

.download-container .description {
    font-family: rawline;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    color: #373737;
    display: table;
    margin-bottom: 10px;
}

.download-container div {
    margin: 0px;
}

.download-container .description span {
    padding-right: 5px;
}

.imgcenter {
    margin: auto !important;
    flex: inherit !important;
}

.download-container-text {
    margin: auto !important;
}

    .download-container-text .title-productdetails {
        font-size: 16px;
    }

.warning-popup {
    width: 400px;
    height: 100px;
    background: linear-gradient(0deg, rgba(235, 45, 91, 0.1), rgba(235, 45, 91, 0.1)), #FFFFFF;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-size: 16px;
    display: flex;
    cursor: auto;
}

    .warning-popup .content-section {
        width: 316px;
        padding: 12px;
        color: #373737;
        font-family: Open Sans;
    }

    .warning-popup .alert-icon-section {
        background-color: #EB2D5B;
        border-radius: 10px 0px 0px 10px;
        width: 58px;
        text-align: center;
    }

        .warning-popup .alert-icon-section img {
            width: 26px;
            margin-left: 0px;
            margin-top: 20px;
        }

    .warning-popup .close-icon-section img {
        width: 16px;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 10px;
    }

.popup-window-mobile-header {
    display: none;
}

.serie-name-wrapper {
    position: unset !important;
    cursor: pointer;
}

.popup-window .first-div.image-container,
.popup-window .third-div {
    width: 25%;
}

.popup-window .second-div {
    width: 50%;
}

.consulta-detalhes-clicaveis-poupanca .tableCapitalCerto-title-inactive {
    display: none;
}

.warning-popup-mobile {
    display: none;
}
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                              Small Desktop                                                                               *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
Screen = B/w 1025px to 1290px
*/
@media(min-width:1025px) and (max-width:1290px) {
    .popup-window {
        width: 100%;
    }
}

/*Small Desktop end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/

@media(min-width:768px) and (max-width:1024px) {
    .popup-window {
        width: 100%;
    }
}
/*Tablet end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:767px) {
    .popup-window {
        width: 100%;
        padding: 10px 20px;
    }

    .warning-popup {
        display: none;
    }

    .warning-popup-mobile {
        text-align: left;
        display: block;
        position: fixed;
        top: 0;
        height: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        background: #F5F5F5;
        width: 100%;
        padding: 10px 20px;
    }

    .popup-window-close {
        display: none;
    }

    .popup-window-mobile-header {
        display: block;
    }

        .popup-window-mobile-header .popup-window-mobile-close {
            width: 35px;
            float: right;
        }

    .title-productdetails {
        font-size: 26px;
        margin-top: 50px;
    }

    .second-div .title-productdetails {
        margin-top: 20px;
    }

    .popup-window .subtitle {
        font-size: 16px;
    }

    .central-container.row {
        display: grid;
        flex-wrap: unset;
        min-width: 240px;
    }

    .popup-window .image-container {
        min-height: 270px;
        width: 100%;
        border-radius: 69px 69px 0px 0px !important;
    }


    .popup-window .central-container .image-container {
        background-repeat: no-repeat;
    }

    .popup-window .first-div.image-container,
    .popup-window .third-div,
    .popup-window .second-div {
        width: 100%;
    }

    .third-div .details {
        display: flex;
    }

        .third-div .details .text1,
        .third-div .details .text2,
        .third-div .details .text3 {
            width: 33%;
        }

        .third-div .details .text1 {
            text-align: left;
        }

        .third-div .details .text2 {
            justify-content: center;
        }

        .third-div .details .text3 {
            justify-content: flex-end;
        }

    .additional-info {
        width: 100%;
    }

    .download-container {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
    }

    .imgcenter {
        width: 20%;
        max-width: 30px;
    }

    .download-container-text {
        width: 80%;
        font-size: 12px;
    }

        .download-container-text .title-productdetails {
            font-size: 12px;
        }

    .col-sm-12.download-title {
        margin-bottom: 30px;
    }

    .additional-info .text-productdetails {
        padding-right: 15px;
        padding-left: 15px;
        width: 80%;
        margin: 0px;
    }

    .carousel-subscricao-nova-modalidade .popup-window {
        text-align: left;
    }
}
/*Smartphone end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Small Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 400px
*/

@media(max-width:400px) {
    .additional-info .image-productdetails {
        width: 65px;
        height: 65px;
    }
}
