.card-new-sub {
    background: #ffffff;
    border: 2px dashed #b2b2b2;
    border-radius: 40px;
    width: 754px;
    height: 100%;
    min-height: 100px;
    margin-bottom: 30px;
    display: flex;
    overflow: hidden;
}

.card-new-sub .card-new-sub-mobile {
    display: none;
}

.card-new-sub-table {
    display: inline-block;
}

.card-new-subs-table-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    height: 100px;
    width: auto;
    padding: 31px 15px 31px 30px;
    background: rgba(109,110,113,.05);
    color: #6d6f71;
    cursor: default;
}

.card-new-sub-table-value {
    font-family: rawline;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #b2b2b2;
}

.card-new-sub-table-title {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 150%;
    color: #b2b2b2;
}

.card-new-sub-table-boldvalue {
    font-family: rawline;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #b2b2b2;
}

.card-new-sub-table-subtitle-unactive {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #b2b2b2;
}

.card-new-sub-create-subscription-button {
    margin-left: 100px;
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
.card-new-sub-create-subscription-button {
    margin-left: 15px;
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
.card-new-sub .card-new-sub-mobile {
    display: block;
    width: 207px;
    text-align: left;
    box-shadow: unset;
    margin-left: auto;
    margin-right: auto;
    height: unset;
}

.card-new-sub .card-new-sub-mobile .card-new-sub-create-subscription-button {
    margin-top: 30px;
    float: right;
    margin-right: 25px;
    margin-bottom: 25px;
}

.card-new-sub .card-new-sub-mobile .card-new-sub-create-subscription-button .card-new-sub img {
    margin: 0px;
}

.card-new-sub .card-new-sub-mobile .card-new-sub-amount, .card-new-sub-amount {
    padding: 20px 20px 0px 20px;
}