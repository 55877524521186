.filter-information {
    width: 50%;
    padding: 15px 15px 15px 0px;
    margin-left: auto;
}

.visible-under-500 {
    display: none;
}

.selecionar-data-button {
    height: 40px;
    line-height: 40px;
    width: 165px;
    cursor: pointer;
    margin-left: 31px;
    border-radius: 25px;
    background: #FF5800;
    color: white;
    text-align: center;
    font-family: rawline;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.data-buttons {
    padding: 0px 14%;
    margin-top: 25px;
    display: flex;
}

.dropdown-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #595959;
    text-transform: uppercase;
    margin-top: 13px;
    width: 102px;
}

.dropdown-greyarea {
    width: 225px;
    height: 45px;
    border-bottom: 1.5px solid #B2B2B2;
    padding: 0px 0px 0px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: unset !important;
}

    .dropdown-greyarea img {
        cursor: pointer;
        margin-right: 11px;
        margin-left: auto;
    }

.dropdown-date {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #FF5800;
}

.filter-information-title {
    font-family: rawline;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #373737;
    padding-bottom: 0px;
}

.filter-information-value {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: #FF5800;
}

.dropdown-popup {
    width: 558px;
    height: 360px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    padding: 25px 28px;
}

.dropdown-greyarea-select {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #FF5800;
    width: 225px;
    height: 30px;
    display: inline-block;
    padding: 0px 20px 0px 20px;
    border: 0px;
    outline: 0px;
    border-bottom: 1.5px solid #B2B2B2;
    background: none;
    cursor: pointer;
    background: url('../../images/expand.png') no-repeat right;
    background-position-x: 200px;
    background-size: 14px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-position-y: 6px;
}

    .dropdown-greyarea-select option {
        font-family: rawline;
        font-weight: bold;
        font-size: 12px;
        line-height: 140%;
        color: #595959;
        border: 1.5px solid #B2B2B2;
    }

select.dropdown-greyarea-select option:hover {
    background: orange;
}
/*CALENDAR*/
.rc-calendar-selected-day .rc-calendar-date {
    background: #FBB995 !important;
    font-family: rawline !important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #373737 !important;
}

.rc-calendar-range .rc-calendar-in-range-cell {
    background: #FBB995 !important;
    font-family: rawline !important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #373737 !important;
}

.rc-calendar-date {
    font-family: rawline !important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #6D6F71 !important;
    width: auto !important;
}

.rc-calendar-selected-date .rc-calendar-date {
    background: #FBB995 !important;
}

.rc-calendar-today .rc-calendar-date {
    border: 1px solid #FBB995 !important;
}

.rc-calendar-body {
    border-bottom: 0px !important;
}

.rc-calendar-range .rc-calendar-footer-btn {
    display: none;
}

.rc-calendar-header {
    border-bottom: none !important;
    font-family: rawline !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #373737 !important;
}

.rc-calendar-column-header {
    padding-bottom: 20px !important;
    font-family: rawline !important;
    font-weight: bold !important;
    color: #373737;
    Line-height: 16,8px !important;
}

.cancelar-data-button {
    height: 40px;
    width: 165px;
    cursor: pointer;
    border-radius: 25px;
    background: white;
    color: #FF5800;
    text-align: center;
    font-family: rawline !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    border: 2px solid #FF5800;
}

.rc-calendar-column-header-inner {
    font-weight: bold !important;
}

.rc-calendar-selected-start-date div {
    border-radius: 20px 0px 0px 20px;
}

.rc-calendar-selected-end-date div {
    border-radius: 0px 20px 20px 0px;
}

.rc-calendar-range .rc-calendar-body, .rc-calendar-range .rc-calendar-decade-panel-body, .rc-calendar-range .rc-calendar-year-panel-body, .rc-calendar-range .rc-calendar-month-panel-body {
    overflow: initial !important;
    border-bottom: 0px !important;
}

.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
    background: #FBB995 !important;
}

rc-calendar-year-panel-decade-select-content {
    pointer-events: none;
}

.rc-calendar-year-panel-last-decade-cell {
    pointer-events: none
}

    .rc-calendar-year-panel-last-decade-cell a {
        color: white !important;
    }

.rc-calendar-year-panel-next-decade-cell {
    pointer-events: none
}

    .rc-calendar-year-panel-next-decade-cell a {
        color: white !important;
    }

.rc-calendar-year-panel-decade-select {
    pointer-events: none;
}

.rc-calendar-month-select {
    pointer-events: none;
}

.rc-calendar-year-panel-body {
    padding: 0 !important;
}

.rc-calendar-range-middle {
    display: none;
}

.rc-calendar-prev-year-btn {
    display: none !important;
}

.rc-calendar-next-year-btn {
    display: none !important;
}

.rc-calendar-header .rc-calendar-prev-month-btn {
    left: -10px !important;
}

.rc-calendar-header .rc-calendar-next-month-btn {
    right: -10px !important;
}

.rc-calendar-last-month-cell div {
    display: none !important;
}

.rc-calendar-next-month-btn-day div {
    display: none !important;
}

/*HOVER*/

.rc-calendar-date:hover {
    background: #FBB995 !important;
}

.rc-calendar-selected-date .rc-calendar-date:hover {
    background: #FBB995 !important;
}

.rc-calendar-header > a:hover {
    color: #FBB995 !important;
}

.rc-calendar-year-select:hover,
.rc-calendar-month-select:hover,
.rc-calendar-day-select:hover {
    color: #FBB995 !important;
}

.rc-calendar-prev-month-btn:hover,
.rc-calendar-next-month-btn:hover,
.rc-calendar-prev-year-btn:hover,
.rc-calendar-next-year-btn:hover {
    color: #FBB995 !important;
}

.rc-calendar-disabled-cell .rc-calendar-date:hover {
    background: #FBB995 !important;
}

.rc-calendar-selected-date .rc-calendar-date:hover {
    background: #FBB995 !important;
}

.rc-calendar-today-btn:hover,
.rc-calendar-ok-btn:hover,
.rc-calendar-time-picker-btn:hover {
    cursor: pointer;
    color: #FBB995 !important;
}

.rc-calendar-time-input:hover {
    border-color: #FBB995 !important;
}

.rc-calendar-time-panel-selected-cell .rc-calendar-time-panel-time:hover {
    background: #FBB995 !important;
    color: white;
}

.rc-calendar-month-panel-header > a:hover {
    color: #FBB995 !important;
}

.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month:hover {
    background: #FBB995 !important;
}

.rc-calendar-year-panel-header > a:hover {
    color: #FBB995 !important;
}

.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year:hover {
    background: #FBB995 !important;
    color: white !important;
}

.rc-calendar-full-header-switcher-normal:hover {
    border-color: #FBB995 !important;
}

.rc-calendar-year-select.rc-calendar-time-status:hover,
.rc-calendar-month-select.rc-calendar-time-status:hover,
.rc-calendar-day-select.rc-calendar-time-status:hover {
    color: #FBB995 !important;
}

.rc-calendar-year-panel-year:hover {
    background: #FBB995 !important;
    color: white;
}

.rc-calendar-cell {
    padding: 0px !important;
}
/*SORTING*/
.-sort-asc {
    box-shadow: none !important;
}

.-sort-desc {
    box-shadow: none !important;
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
/*Necessario porque no tamanho de 768 até 880 não há espaço para tudo o que existe nos ecrãs*/
@media(min-width:768px) and (max-width:880px) {
    .dropdown-greyarea {
        padding: 0px 0px 0px 10px;
    }

    .dropdown-greyarea-select {
        background-position-x: 183px;
    }

    .cancelar-data-button {
        display: none;
        font-family: rawline !important;
    }
}

/*Tablet end*/


/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:767px) {
    .filter-dropdown {
        display: block;
    }

    .dropdown-greyarea,
    .dropdown-greyarea-select {
        padding: 0px 0px 0px 5px;
    }

    .dropdown-popup {
        text-align: -webkit-center;
        overflow: scroll;
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        background: #FFFFFF;
    }

    .rc-calendar-date-panel, .rc-calendar-panel {
        display: grid;
    }

    .rc-calendar-range-part {
        width: 100%;
        max-width: 350px;
        margin: auto;
        font-size: 20px;
    }

    .rc-calendar-year-select, .rc-calendar-month-select, .rc-calendar-day-select {
        font-size: 20px;
    }

    .rc-calendar-date {
        font-size: 17px;
    }

    .rc-calendar-range {
        width: 100%;
        padding-top: 15px;
    }

    .selecionar-data-button {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 380px;
        font-family: rawline !important;
    }

    .cancelar-data-button {
        display: none;
        font-family: rawline !important;
    }

    .data-buttons {
        padding: 0px;
    }
}
/*Smartphone end*/


/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Small Smartphone                                                                        *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 580px
*/
@media(max-width:580px) {
    .divfilter {
        width: 100%;
    }

    .filter-dropdown {
        margin: auto 0 auto 15px;
    }
}

/* 
Device = Smartphones
Screen = B/w until 500px
*/
@media(max-width:500px) {
    .divfilter {
        display: inline-table;
    }

    .filter-information {
        margin: auto;
        width: 100%;
        text-align: center;
    }

    .filter-dropdown {
        width: 225px;
        margin: 20px auto;
    }

    .dropdown-greyarea-select {
        background-position-x: 175px;
    }

    .dropdown-greyarea,
    .dropdown-greyarea-select,
    .dropdown-benefits-button {
        padding: 0px;
    }

    .visible-under-500 {
        display: inherit;
    }

    .filter-information:not(.visible-under-500) {
        display: none;
    }
}

/* 
Device = Smartphones
Screen = B/w until 360px
*/
@media(max-width:360px) {
    .dropdown-greyarea,
    .dropdown-greyarea-select {
        width: 180px;
    }

    .dropdown-date {
        font-size: 11px;
    }

    .dropdown-greyarea-select {
        background-position-x: 155px;
    }
}
/*Small Smartphone end*/

