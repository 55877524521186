
.error-title {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #373737;
    margin-bottom: 10px;
}

.error-description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #373737;
    margin-bottom: 20px;
}

.error-telephone {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    align-items: center;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FF5800;
}
