.highlight-div{
    margin-top: 50px;
    width: 754px;
}

.highlight-div-text{
    float: right;
    font-family: rawline;
    font-weight: 600;
    font-size: 11px;
    line-height: 140.88%;
    color: #C4C4C4;
}

@media(min-width:768px) and (max-width:880px) {
    .highlight-div{
        width: 100%;
    }
}

@media(max-width:767px) {
    .highlight-div{
        display:none;
    }
}