/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
.form-create-saving {
  position: absolute;
  z-index: 0 !important;
}
/*Necessario porque no tamanho de 768 até 880 não há espaço para tudo o que existe nos ecrãs*/
@media (min-width: 768px) and (max-width: 880px) {
  .form-create-saving {
    width: 100% !important;
    position: relative;
  }
}
/*Tablet end*/
