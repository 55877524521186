.title.eleicoes-title {
  width: 100%;
}

.sub-title-bar {
  margin-top: 60px;
  width: 754px;
}

.sub-title-bar .sub-title.eleicoes-sub-title {
  color: #ff5800;
  font-size: 24px;
  font-weight: bold;
}

.sub-title-bar .disclaimer.eleicoes-disclaimer {
  color: #595959;
  font-size: 16px;
  margin-top: 20px;
  font-family: Open Sans;
}

.convocatoria-bar .card-new-title {
  color: #ff5800;
  font-weight: bold;
  font-size: 18px;
}

.convocatoria-bar .card-disclaimer {
  color: #373737;
  font-weight: normal;
  font-size: 14px;
  width: 340px;
  font-family: Open Sans;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-disclaimer.full-width {
  width: auto !important;
}

.button-vote img {
  cursor: pointer;
  width: 122px;
}

.convocatoria-bar.voto-bar .card-disclaimer {
  width: 380px;
}

.convocatoria-bar {
  margin-top: 40px;
}

.convocatoria-bar .card-new img {
  margin-left: 0px;
}

.duvidas-bar .card-duvidas {
  background-color: #ff5800;
  border: none;
}

.poupe-bw-peq{
    font-size: 20px;
}

.duvidas-bar .no-padding {
    padding: 0px;
}

.duvidas-bar .card-new img {
  margin-top: 0px;
}

.duvidas-column {
  color: white;
}

.duvidas-column-mobile {
  background-color: #ff5800;
}

.duvidas-column .duvidas {
  font-weight: bold;
  font-size: 18px;
  font-family: rawline;
}

.duvidas-column .contacto {
  font-weight: bold;
  font-size: 14px;
  font-family: Open Sans;
}

.duvidas-column .horario {
  font-weight: normal;
  font-size: 14px;
  font-family: Open Sans;
}

.card-new.card-new-mobile.card-convocatoria-mobile .card-new-title {
  padding-top: 50px;
}

.modalidades.modalidades-mobile.duvidas-bar {
  margin-right: auto;
  margin-left: auto;
}

.card-new.card-new-mobile.card-convocatoria-mobile .tableSaberMais {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
}

.convocatoria-bar.voto-bar .card-new {
  box-shadow: 0 20px 40px rgb(0 0 0 / 20%);
  border: none;
}

.convocatoria-bar.voto-bar .card-new-title {
  background-color: #fff7f2;
}

.card-convocatoria-mobile .tableCapitalCerto-amount {
  font-family: Open Sans;
}

@media (max-width: 767px) {
  .sub-title-bar {
    margin-top: 20px;
  }

  .convocatoria-bar {
    margin-top: 10px;
  }

  .sub-title.eleicoes-sub-title {
    margin-bottom: 0px;
  }

  .card-convocatoria-mobile,
  .modalidades.modalidades-mobile.duvidas-bar,
  .tabelasCandidatos {
    width: 60% !important;
    max-width: 320px;
    min-width: 290px;
  }

  .duvidas-column.duvidas-column-mobile .duvidas {
    padding-left: 19px;
    padding-top: 20px;
  }

  .duvidas-column.duvidas-column-mobile .contacto {
    padding-left: 19px;
    margin-top: 13px;
  }

  .duvidas-column.duvidas-column-mobile .horario {
    padding-left: 19px;
    padding-bottom: 56px;
  }
}

@media (min-width: 750px) and (max-width: 880px) {
  .convocatoria-bar .card-disclaimer {
    width: 250px;
  }

  .convocatoria-bar.voto-bar .card-disclaimer {
    width: 295px;
  }
}

.botao-votar-mobile {
  background-color: white;
  color: #ff5800;
  padding: 10px;
  width: 180px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  font-family: rawline;
  text-align: center;
}

.eleicoes-mobile-title {
  font-weight: bold;
  font-size: 18px;
  font-family: rawline;
  margin-left: 50px;
  margin-right: 60px;
}

.popupEleicoesLateral .votar-mobile-close {
  right: 19px;
  top: 23px;
}

.popupEleicoesLateral .imagem-eleicoes {
  margin-top: 32px;
}

.popupEleicoesLateral .botao-votar-mobile {
  padding: 10px;
  width: 104px;
  font-size: 14px;
}

.popupEleicoesLateral .botao-votar-mobile img {
  margin-left: 5px;
}

.votar-mobile-close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.botao-votar-mobile img {
  margin-left: 10px;
}

.imagem-eleicoes {
  width: 50px;
}

.card-new.card-new-mobile.card-convocatoria-mobile.card-votar-mobile {
  border: none;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.card-new.card-new-mobile.card-convocatoria-mobile.card-votar-mobile
  .tableCapitalCerto-title-inactive {
  color: #ff5800;
}

.card-new.card-new-mobile.card-convocatoria-mobile.card-votar-mobile
  .card-new-title {
  background: #fff7f2;
}

.card-new.card-new-mobile.card-convocatoria-mobile.card-votar-mobile
  .tableCapitalCerto-amount {
  padding: 20px 20px 20px 20px;
}

.card-new.card-new-mobile.card-convocatoria-mobile.card-votar-mobile
  .button-vote
  img {
  margin-left: 20px;
  margin-bottom: 20px;
}

#votar-mobile.show {
  display: flex;
}
#votar-mobile.hide {
  display: none;
}

#votar-mobile.remove {
  display: none !important;
}

@media (min-width: 500px) and (max-width: 650px) {
  .eleicoes-mobile-title {
    margin-left: 30px;
    margin-right: 40px;
  }

  .botao-votar-mobile {
    width: 130px;
  }
}

@media (max-width: 500px) {
  .imagem-eleicoes {
    width: 40px;
  }

  .eleicoes-mobile-title {
    margin-left: 15px;
    margin-right: 20px;
  }

  .botao-votar-mobile {
    width: 110px;
  }
}

.footer-votar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    212.78deg,
    #fbb995 4.17%,
    #f47b44 46.35%,
    #ff5800 94.79%
  );
  color: white;
  height: 80px;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.botao-votar-mobile {
  background-color: white;
  color: #ff5800;
  padding: 10px;
  width: 180px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  font-family: rawline;
  text-align: center;
}

.botao-votar-mobile:hover {
  color: #ff5800;
  text-decoration: none;
}

.eleicoes-mobile-title {
  font-weight: bold;
  font-size: 18px;
  font-family: rawline;
  margin-left: 50px;
  margin-right: 60px;
}

.botao-votar-mobile img {
  margin-left: 10px;
}

.imagem-eleicoes {
  width: 50px;
}

@media (min-width: 500px) and (max-width: 650px) {
  .eleicoes-mobile-title {
    margin-left: 30px;
    margin-right: 40px;
  }

  .botao-votar-mobile {
    width: 130px;
  }
}

@media (max-width: 500px) {
  .imagem-eleicoes {
    width: 40px;
  }

  .eleicoes-mobile-title {
    margin-left: 15px;
    margin-right: 20px;
  }

  .botao-votar-mobile {
    width: 110px;
  }
}

/**.popup-elections-img {
  width: 81.5px;
}**/

.popup-elections {
    width: 598px;
    /*height: auto !important;*/
    background-color: #ffffff;
    height: 465px;
    border-radius: 50px;
    box-shadow: 0px 20px 40px rgb(0 0 0 / 20%);
    text-align: center;
    /*overflow-y: scroll!important;*/
}

.popup-black-friday {
    width: 558px;
    background-color: black;
    color: #ff5800;
    padding: 25px;
    height: auto;
    border-radius: 50px;
    box-shadow: 0px 20px 40px rgb(0 0 0 / 20%);
    text-align: inherit;
}

.popup-black-friday .poupar-div {
  color: white;
  font-size: 25px;
}

.popup-black-friday .poupar-div .vale {
  font-size: 30px;
  font-weight: bold;
}

.popup-black-friday .black-teaser,
.popup-black-friday .week-teaser {
  font-size: 80px;
  font-weight: bold;
  line-height: initial;
}

.popup-black-friday .data-teaser {
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
}

.popup-black-friday .fique-atento-teaser {
  font-size: 18px;
  font-weight: bold;
}

.popup-black-friday .black-friday-close-div {
  text-align: right;
}

.popup-black-friday .black-friday-close-div img {
  cursor: pointer;
}
    .popup-black-friday .black-week-bw {
        font-size: 45px;
        font-weight: bold;
        line-height: initial;
    }



.popup-black-friday .poupe-bw {
  color: white;
  font-size: 25px;
}
.popup-black-friday .vale-bw {
  font-size: 53px;
  font-weight: bold;
  line-height: initial;
}
.popup-black-friday .compras-bw {
  font-size: 17px;
  color: white;
  margin-bottom: 15px;
}

.popup-elections-fechar {
  width: 128px;
  height: 40px;
  border: 2px solid #ff5800;
  border-radius: 50px;
  margin-right: 100px;
  color: #ff5800;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  padding-left: 25px;
}

.popup-elections-fechar-center {
    width: 128px;
    height: 40px;
    border: 2px solid #ff5800;
    border-radius: 50px;
    color: #ff5800;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    padding-left: 25px;
    margin-top: 23px;
}

.popup-elections-buttons {
  display: inline-flex;
  align-items: center;
}

.popup-elections-title,
.popup-elections-title-mobile {
    font-family: rawline;
    font-size: 26px;
    font-weight: bold;
    color: #ff5800;
    margin-bottom: -8px;
}
.popup-musicaolicos-title,
.popup-musicaolicos-title-mobile {
    font-family: rawline;
    font-size: 22px;
    font-weight: bold;
    color: #ff5800;
    margin-bottom: -8px;
    margin-top: 30px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -70%);
    top: auto;
    line-height: 1.5;
}

    .popup-musicaolicos-title-mobile p {
        
        white-space: nowrap;
    }

    .popup-elections-img {
        margin-top: 25px;
        /*margin-bottom: 28px;*/
        width: 240px;
    }

.popup-primavera-img {
    width: 100%;
    height: auto;
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    /* radius ajustado para o mesmo css na div(.popup-elections) */
    border-left: 2px solid hidden;
    border-top-left-radius: 50px;

    border-right: 2px solid hidden;
    border-top-right-radius: 50px;

    margin-bottom: 15px;
}

.popup-elections-text {
    font-family: Open Sans;
    font-weight: bold;
    font-size: 14px;
    color: black;
    margin-top: 25px;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.wrap-popup-btt button {
    
    margin-left: 30%;
}

.popup-elections-text p{
    font-family: Open Sans;
    font-weight: bold;
    font-size: 12px;
    color: black;
    margin-top: 25px;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.popup-elections-text a , .popup-elections-text b  {
    color: #ff5800;
}

@media (max-width: 580px) {
    .popup-elections, .popup-black-friday {
    width: auto; /* 100% */
    height: 100%!important;
    /*display: contents!important;*/
    overflow-y: scroll!important;
  }

  .popup-black-friday.teaser {
    padding: 25px 40px;
  }

  .popup-black-friday .poupar-div {
    font-size: 20px;
  }

  .popup-black-friday .poupar-div .vale {
    font-size: 25px;
  }

  .popup-black-friday .black-teaser,
  .popup-black-friday .week-teaser {
    font-size: 70px;
  }

  .popup-black-friday .fique-atento-teaser {
    font-size: 18px;
  }

  .popup-black-friday .black-week-bw {
    font-size: 60px;
  }
  .popup-black-friday .poupe-bw {
    font-size: 20px;
  }
  .popup-black-friday .vale-bw {
    font-size: 45px;
  }
  .popup-black-friday .compras-bw {
    font-size: 17px;
  }

  .popup-content.popupElection-content {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .popup-black-friday .black-week-bw {
    font-size: 45px;
  }
  .popup-black-friday .poupe-bw {
    font-size: 15px;
  }
  .popup-black-friday .vale-bw {
    font-size: 30px;
  }
  .popup-black-friday .compras-bw {
    margin-bottom: 15px;
  }
}

@media (max-width: 420px) {
  .popup-elections-fechar {
    margin-right: 50px;
  }
  .popup-black-friday .black-week-bw {
    font-size: 35px;
  }

  .popup-black-friday .poupar-div {
    font-size: 16px;
  }

  .popup-black-friday .poupar-div .vale {
    font-size: 20px;
  }

  .popup-black-friday .vale-bw {
    font-size: 25px;
  }

  .popup-black-friday .compras-bw {
    margin-bottom: 13px;
  }

  .popup-black-friday .compras-bw {
    font-size: 13px;
  }
}

@media (max-width: 350px) {
  .popup-elections-fechar {
    margin-right: 20px;
  }
}

.popupEleicoesLateral {
  position: fixed;
  display: block;
  background: linear-gradient(
    212.78deg,
    #fbb995 4.17%,
    #f47b44 46.35%,
    #ff5800 94.79%
  );
  width: 136px;
  right: 0px;
  top: 30%;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  padding: 16px;
  text-align: center;
  z-index: 1;
}

.eleicoes-side-title {
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.popup-elections-votar,
.popup-elections-votar:hover {
  color: #ffffff;
  background-color: #ff5800;
  border-radius: 35px;
  width: 160px;
  padding: 7px;
  font-family: rawline;
  font-weight: bold;
  font-size: 16px;
}

.popup-elections-votar img {
  margin-left: 10px;
  margin-bottom: 3px;
}

.sub-title-bar .sub-title.eleicoes-sub-title.black {
  color: black;
}

@media (max-width: 767px) {
  .popupEleicoesLateral {
    display: none;
  }

  .popup-elections-title {
    display: none;
  }

  .sub-title-bar .disclaimer.eleicoes-disclaimer {
    display: none;
  }

  .sub-title-bar .sub-title.eleicoes-sub-title.black {
    color: black;
  }
}


