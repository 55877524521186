.main-home {
  float: right;
  margin-right: 20%;
}

.title-benefits {
  position: absolute;
  top: 78%;
  left: 135%;
  transform: translate(-50%, -50%);
  text-align: left;
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  color: #373737;
  margin-bottom: 20px;
  white-space: pre;
}

.detail-benefits {
  position: absolute;
  top: 104%;
  left: 124%;
  transform: translate(-50%, -50%);
  text-align: left;
  font-family: "rawline", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16.8px;
  display: flex;
  color: #373737;
  margin-bottom: 20px;
  white-space: pre;
  cursor: pointer;
}

.plus-icon-orange {
  position: absolute;
  top: 94%;
  left: 124%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 12%;
  height: 12%;
  cursor: pointer;
}

.main {
  padding-top: 95px !important;
}

.carousel-wrapper {
  display: none;
}

.benefits-pie-image-center {
  width: 51px;
  height: 50px;
}

.pie-div {
  width: 450px;
  height: 450px;
  z-index: 0 !important;
  margin-bottom: 110px;
}

.pie-div {
  margin-left: 55px;
}

.beneficios-container .pie-div {
  margin-left: auto;
  margin-right: auto;
}

.pie-information {
  padding: 0px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.benificios-card-img {
  height: 294px;
  width: 360px;
}

.beneficios {
  display: flex;
}

.modalidades-home {
  display: flex;
  margin-top: 75px;
}

.title-home {
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #373737;
  margin-bottom: 20px;
}

.subscribe {
  display: flex;
  bottom: 0;
  margin-top: 5px;
}

.subscribe img {
  margin-top: 25px;
  display: flex;
}

.subscribe div {
  display: flex;
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  margin-top: 13px;
  margin-left: 10px;
  align-items: center;
  color: #373737;
}

.poupanca-title {
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #373737;
  margin-bottom: 30px;
}

.poupanca-card {
  background: linear-gradient(
    218.51deg,
    #fffdfd 0%,
    #f6f6f6 52.6%,
    #e1e1e1 100%
  );
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  height: 325px;
  width: 263px;
  padding: 40px 30px;
}

.poupanca-card:hover {
  background: linear-gradient(
    218.51deg,
    #edf3ff 4.17%,
    #8cadda 46.35%,
    #4a8eb3 94.79%
  );
  cursor: pointer;
}

.poupanca-card:hover div {
  color: white;
}

.poupanca-card:hover .poupanca-gain {
  border-bottom: 2px solid white;
}

.poupanca-card:hover path,
.poupanca-card:hover line {
  stroke: white;
}

.poupanca-income {
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  color: #1f6a93;
}

.poupanca-income-protecao {
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  color: #1f6a93;
}

.poupanca-gain {
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #1f6a93;
  border-bottom: 2px solid #1f6a93;
  padding-bottom: 6px;
  display: inline-block;
}

.poupanca-description {
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #373737;
  margin-top: 6px;
}

.protecao {
  margin-left: 40px;
}

.protecaopoupancadiv {
  display: inline-block;
  height: 325px;
  width: 460px;
  background: linear-gradient(
    204.51deg,
    #fffdfd 0%,
    #f6f6f6 52.6%,
    #e1e1e1 100%
  );
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
}

.protecaopoupanca {
  padding-top: 40px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 40px 0px 0px 40px;
  height: 325px;
  width: 230px;
  display: inline-block;
  border-right-style: solid;
  border-right-color: #dddddd;
  border-right-width: 2px;
}

.protecaopoupanca:hover {
  background: linear-gradient(
    222.3deg,
    #edf3ff 4.17%,
    #8cadda 46.35%,
    #4a8eb3 94.79%
  );
  border-radius: 40px 0px 0px 40px;
  cursor: pointer;
}

.protecaopoupanca:hover div {
  color: white;
}

.protecaopoupanca:hover .poupanca-gain {
  border-bottom: 2px solid white;
}

.protecaopoupanca:hover path,
.protecaopoupanca:hover line {
  stroke: white;
}

.protecaomod {
  border-radius: 0px 40px 40px 0px;
  padding: 30px 30px;
  display: inline-block;
  width: 230px;
  height: 325px;
  padding-top: 40px;
}

.protecaomod:hover {
  background: linear-gradient(
    222.3deg,
    #edf3ff 4.17%,
    #8cadda 46.35%,
    #4a8eb3 94.79%
  );
  border-radius: 0px 40px 40px 0px;
  cursor: pointer;
}

.protecaomod:hover div {
  color: white;
}

.protecaomod:hover .poupanca-gain {
  border-bottom: 2px solid white;
}

.protecaomod:hover path,
.protecaomod:hover line {
  stroke: white;
}

.table-benefits {
  margin-top: 100px;
  margin-left: 7%;
}

.table-benefits .down-group {
  display: flex;
}

.table-title {
  height: 35px;
  padding-bottom: 20px;
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #373737;
}

.table-img {
  padding-right: 20px;
}

.table-benefits .table-img img {
  width: 47px;
}

.table-gain {
  font-family: "rawline", sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #ff5800;
  height: 0px;
  margin-top: 19px;
  padding-bottom: 15px;
}

.table-gain div {
  border-bottom: 2px solid #ff5800;
}

.table-description {
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #373737;
  width: 138px;
  margin-top: 15px;
}

/*Necessario porque no tamanho de 768 at� 880 n�o h� espa�o para tudo o que existe nos ecr�s*/
@media (min-width: 768px) and (max-width: 835px) {
  .pie-div {
    margin-left: 35px !important;
    width: 420px;
    height: 420px;
  }
}
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/

/*Necessario porque no tamanho de 768 at� 880 n�o h� espa�o para tudo o que existe nos ecr�s*/
@media (min-width: 768px) and (max-width: 880px) {
  .pie-div {
    margin-left: 10px;
  }

  .table-benefits {
    margin-left: 2%;
  }

  .poupanca-card {
    width: 230px;
  }

  .protecaopoupanca,
  .protecaomod {
    width: 215px;
  }

  .protecaopoupancadiv {
    width: 430px;
  }

  .protecao {
    margin-left: 20px;
  }
}

/*Tablet end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media (max-width: 767px) {
  .beneficios {
    display: block;
  }

    .zero-benefits{
        width:100%;
    }

    .benificios-card {
        text-align: center;
    }

  .table-benefits {
    margin-top: 41px;
    margin-left: auto;
    margin-right: auto;
    display: table;
  }

  .table-title {
    display: none;
  }

  .down-group {
    align-items: center;
  }

  .table-benefits .table-img img {
    width: 61px;
  }

  .modalidades-home {
    display: none;
  }

  .carousel {
    position: unset;
  }

  .carousel-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    margin-top: 55px;
    text-align: -webkit-center;
  }

  .poupanca {
    max-width: 263px;
    text-align: left;
  }

  .poupanca-card {
    box-shadow: none;
    text-align: left;
  }

  .carousel__dot {
    border-radius: 10px;
    width: 15px;
    height: 15px;
    border: 0;
    background: #c4c4c4;
    margin: 0px 10px;
  }

  .carousel__dot--selected {
    background: #ff5800;
    border-radius: 10px;
    width: 30px;
    height: 15px;
  }

  .carousel__slider {
    height: 405px;
  }
}
/*Smartphone end*/
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Small Smartphones                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Small Smartphones
Screen =  B/w until 500px
*/
@media (max-width: 500px) {
  .benificios-card-img1 {
    width: 300px;
    height: auto;
  }

  .table-gain {
    font-size: 30px;
  }
}

/* 
Device = Small Smartphones
Screen =  B/w until 500px
*/
@media (max-width: 400px) {
  .benificios-card-img {
    width: 260px;
    height: auto;
  }
}

@media (max-width: 576px) {
  .pie-div {
    width: 380px;
    height: 380px;
  }
}

@media (max-width: 767px) {
  .title-benefits,
  .plus-icon-orange,
  .detail-benefits {
    display: none;
  }

  .pie-div {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: unset;
  }

  .beneficios-container .pie-div {
    margin-bottom: 70px;
  }
}

@media (max-width: 510px) {
  .pie-div {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 480px) {
  .pie-div {
    width: 320px;
    height: 320px;
  }
}

/*Small Smartphones end*/
