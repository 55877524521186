.no-mail-div {
    text-align: center;
}

.no-mail-img {
    width:100px;
}

.no-mail-text {
    font-family: rawline;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: #373737;
}

.mail-date {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
}

.mail-name {
    font-family: rawline;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}

    .mail-name span{
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width:200px;
    }

.notifications-list-wrapper{
    margin-top: 60px;
    background: #FFFFFF;
    width: 753px;
    border-top: 2px solid black;
}

    .notifications-list-wrapper .ReactTable .rt-tbody .rt-tr-group{
        padding: 0;
    }
    .notifications-list-wrapper .rt-thead.-header {
        display: none;
    }

    .notifications-list-wrapper .pagination-bottom {
        padding-top: 30px;
        padding-bottom: 57px;
        background: #F5F5F5;
    }

    .notifications-list-wrapper .ReactTable .rt-tbody .rt-tr-group {
        border-bottom: 1px solid #DDDDDD;
        background: #F5F5F5;
    }

    .title-bar.title-bar-mail .title {
        width: 100%;
    }

    .title-bar.title-bar-mail {
        height:unset;
        overflow:unset;
    }
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 800px
*/
@media(max-width:880px) {
    .notifications-list-wrapper{
        width: 100%;
    }
    /*Smaller than 600*/
    @media(max-width:740px) {
        .mail-name span{
            width: 150px;
        }
    }
    /*Smaller than 420 end*/
/*Smartphone end*/
}