/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.disabled-link {
    cursor: not-allowed;
}

    .disabled-link a {
        pointer-events: none;
    }

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.main {
    padding-top: 155px;
    padding-left: 35%;
    padding-bottom: 15px;
}

.with-background-image {
    background: url( images/homeBackground.png ) no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
}

.popup-overlay {
    background: rgba(255, 255, 255, 0.5) !important;
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                              Small Desktop                                                                               *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Screen = B/w 1025px to 1290px
*/
@media(min-width:1025px) and (max-width:1290px) {
    .main {
        padding-left: 205px;
    }
}
/*Small Desktop end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/

@media(min-width:768px) and (max-width:1024px) {
    .main {
        padding-left: 0px;
    }
}

/*Necessario para contornar os styles do bootstrap*/
@media(min-width:992px) and (max-width:1024px) {
    .main {
        padding-left: 12%;
    }
}

/*Necessario porque no tamanho de 768 até 880 não há espaço para tudo o que existe nos ecrãs*/
@media(min-width:768px) and (max-width:880px) {
    .main {
        padding-left: 30px;
    }
}
/*Tablet end*/

@media (min-width: 1200px) {
    .container {
        max-width: 960px !important;
    }
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
    @media(max-width:767px) {
        .main {
            margin: 0px;
            padding-left: 0px;
            padding-top: 70px;
        }

        .container {
            max-width: 100%!important;
        }

        .with-background-image {
            background: url( images/homeBackgroundMobile.png ) no-repeat;
            background-size: cover;
        }        
    }
/*Smartphone end*/




