.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.modalidades-mobile-detalhes {
  display: none;
}

.capital-subscrito-valor {
  padding-left: 25px;
}

.poupan�a-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #373737;
}

.poupan�a-card {
  background: linear-gradient(
    218.51deg,
    #fffdfd 0%,
    #f6f6f6 52.6%,
    #e1e1e1 100%
  );
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  padding: 5% 5%;
}

.protecao-poupanca-subscricoes-ativas {
  font-family: rawline;
  font-weight: bold;
  font-size: 14px;
  color: #f47b44;
}

.preheader-div {
  padding-top: 25px;
}

.umbrella {
  margin-left: 50px;
}

.protecao-value-title {
  font-family: rawline;
  font-weight: 800;
  font-size: 10px;
}

.protecao-value {
  font-family: rawline;
  font-weight: bold;
  font-size: 30px;
  color: #ff5800;
}

/*---  DESIGN GUIDE ---*/
.protecao-subscricoes-ativas {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
}

.protecaopoupanca-subscricoes-ativas {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  width: 65%;
}

.subscricoes-ativas-value {
  font-family: rawline;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  padding-left: 10px;
  color: #ff5800;
  width: 100%;
}

.card-protecao-lista-col1-firstline-single {
  min-width: 226px;
  width: 30%;
  height: 100%;
  background: rgba(255, 88, 0, 0.05);
  padding-left: 30px;
  padding-top: 16px;
  padding-bottom: 15px;
  cursor: pointer;
}

.table {
  margin-bottom: -6px !important;
}

.td {
  border: none;
  border-spacing: 0px;
}

.tg {
  border-spacing: 10px;
}

.tg td,
th {
  border-spacing: 10px;
  border: none !important;
  padding-bottom: 12px;
}

.table-value-col1 {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  color: #ff5800;
}

.table-value-col2 {
  width: 85px;
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #373737;
  padding-top: 0.75rem;
}

.table-value-col3 {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  color: #b2b2b2;
  padding-top: 0.75rem;
}

.table-value2-col1 {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 13px;
  color: #585858;
}

.table-value2-col2 {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #373737;
}

.table-value2-col2:hover {
  cursor: pointer;
}

.table-value2-col3 {
  width: 190px;
  font-family: rawline;
  font-weight: bold;
  font-size: 14px;
  color: #595959;
}

.tableCapitalCerto {
  height: 100%;
}

.modalidades {
  padding: 0px;
  overflow: hidden;
}

/*  box com sombra  */
.card-box {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: none;
  background-clip: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 40px;
  padding: 0px;
  overflow: hidden;
  width: 754px;
}

.protecao-card:not(:last-child) {
  border-bottom: 1px solid #b2b2b2;
}

.radiobutton-font {
  height: 45px;
  width: 195px;
  font-family: Open Sans;
  font-size: 16px;
  line-height: 160%;
  color: #6d6f71;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.radiobutton-montepio {
  background: #ffffff;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
}

.radiobutton-montepio img {
  margin-left: 16px;
  margin-right: 16px;
}

.radiobutton-montepio-selected {
  background: rgba(184, 220, 138, 0.1);
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
}

.radiobutton-montepio-selected img {
  margin-left: 16px;
  margin-right: 16px;
}

.radiobutton-text {
  margin-right: 13px;
}
/* Subscrever nova modalidade */
.subscribe-text {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #373737;
  width: 754px;
}

/* Aumente a sua prote��o. Indique-nos a modalidade que gostava de subscrever que entramos em contacto em 24 horas */
.subscribe-text-sub {
  font-family: rawline;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #6d6f71;
  padding-bottom: 25px;
  width: 754px;
}

/*New modality*/
.card-new-protection-content {
  margin-top: 15px;
  margin-left: 38px;
}

.card-new-protection {
  background: #ffffff;
  border: 2px dashed #b2b2b2;
  box-sizing: border-box;
  border-radius: 40px;
  width: 754px;
  height: 100px;
  overflow: hidden;
  margin-top: 80px;
}


.new-series-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  align-items: center;
  color: #6d6f71;
  padding-bottom: 0px;
}

.new-series-subtitle {
  font-family: Open Sans;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #6d6f71;
}

/*New Modality End*/

/*  box Subscricao de modalidade */
.card-protecao-adicionar-rectangle {
  background: #ffffff;
  border: 2px dashed #b2b2b2;
  box-sizing: border-box;
  border-radius: 40px;
}

/* Subscri��o de modalidade */
.subscricao-text {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #6d6f71;
}

/* Agende um contacto para dar seguimento ao processo de subscri��o */
.subscricao-text-sub {
  font-family: Open Sans;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #6d6f71;
}

.detalhe-totalentregue-table {
  margin-left: 14px;
  margin-top: 10px;
  width: 96%;
}

.detalhe-totalentregue-tr {
  width: 100%;
  padding: 21px;
  background: rgba(221, 221, 221, 0.5);
}

.detalhe-quotas-tr {
  width: 100%;
  padding: 21px;
}

.detalhe-totalentregue-label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #6d6f71;
  margin-top: 4px;
}

.detalhe-totalentregue-label-with-contract {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #6d6f71;
  margin-top: 6px;
}

.detalhe-totalentregue-prazo {
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #373737;
  margin-top: 4px;
  text-align: right;
  display: inline-block;
}

.detalhe-totalentregue-valor {
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #373737;
  margin-top: 4px;
  text-align: right;
  display: inline-block;
  padding-right: 0.75rem;
}

.detalhe-outros-label {
  width: 100%;
  max-width: 350px;
  font-family: rawline;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #6d6f71;
  padding-left: 0.75rem;
}

.detalhe-outros-valor {
  font-family: rawline;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #636363;
  text-align: right;
  margin-right: 0.75rem;
}

/*Collapsible Test*/
.Collapsible__contentInner {
  padding: 21px;
}
/*Collapsible End*/

/*Popup contactar*/
.contact-window {
    height: 394px;
    width: 558px;
    background: #ffffff;
    border-radius: 40px;
    padding: 50px 32px;
    box-shadow: 0px 3px 25px rgba(54, 54, 54, 0.1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); /*responsividade do popup*/
}

.contact-window-2 {
  height: 503px;
  width: 559px;
  background: #ffffff;
  border-radius: 40px;
  padding: 50px 32px;
  box-shadow: 0px 3px 25px rgba(54, 54, 54, 0.1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.contact-window-maintext {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #373737;
  border-radius: 15px;
  margin-bottom: 58px;
  margin-left: 67px;
  margin-right: 67px;
}

.contact-window-subtext {
  font-family: Open Sans;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #373737;
  margin-bottom: 30px;
}

.create-protection-button {
  margin-left: 181px;
  margin-top: 5px;
  cursor: pointer;
  height: 50px;
  width: 50px;
}

.resumo-poupanca .third-div,
.new-protecao-area-mobile {
  display: none;
}

/*Popup contactar end*/

/* New Protection Subs start*/
.card-new-sub.card-new-sub-mobile {
    display: none;
}

.card-new-sub-table {
    display: inline-block;
}

.card-new-subs-table-title {
    font-family: rawline;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    height: 100px;
    width: auto;
    padding: 31px 15px 31px 30px;
    background: rgba(109,110,113,.05);
    color: #6d6f71;
    cursor: default;
}

.card-new-sub-table-value {
    font-family: rawline;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #b2b2b2;
}

.card-new-sub-table-title {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 150%;
    color: #b2b2b2;
}

.card-new-sub-table-boldvalue {
    font-family: rawline;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: #b2b2b2;
}

.card-new-sub-table-subtitle-unactive {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #b2b2b2;
}

.card-new-sub-create-subscription-button {
    margin-left: 100px;
}

#protection-create-subscription-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}


#protection-create-subscription-button img {
   width: 1.3rem;
   margin-right: 0.5rem;
   margin-top: 0;
   margin-left: 0;
 }

.protection-create-subscription-button-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #ff5800;
}

/* New Protection Subs end*/
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
/*Necessario porque no tamanho de 768 at� 880 n�o h� espa�o para tudo o que existe nos ecr�s*/
@media (min-width: 768px) and (max-width: 880px) {
    .card-box, .modalidades-bar-2, .card-new-protection {
    width: 100%;
  }

  .create-protection-button {
    margin-left: 90px;
  }

  .entrega-periodica-valor {
    padding-left: 45px;
  }

  .capital-subscrito-valor {
    padding-left: 10px;
  }
}

.card-new-sub-create-subscription-button {
    margin-left: 15px;
}

/*Tablet end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media (max-width: 767px) {
  .title-bar {
    width: 100%;
  }

  .modalidades-bar {
    width: 100%;
    margin-top: 35px;
  }

  .resumo-poupanca {
    display: grid;
    margin-bottom: 18px;
  }

  .resumo-poupanca .first-div {
    display: none;
  }

  .resumo-poupanca .second-div {
    margin-right: auto;
  }

  .resumo-poupanca .third-div {
    margin-top: 40px;
    display: block;
  }

  .resumo-poupanca .third-div .table-subscricoes {
    padding-left: 0px;
  }

  .modalidades,
  .card-new,
  .card-new-protection {
    display: none;
  }

  .card-new-protection.card-new-protection-mobile {
    display: block;
    margin-top: 20px;
  }

  .new-protecao-area-mobile {
    display: block;
    margin-top: 60px;
  }

  .new-protecao-area-mobile .new-protecao-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #373737;
  }

  .new-protecao-area-mobile .new-protecao-label {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #6d6f71;
    margin-top: 10px;
  }

  .modalidades.modalidades-mobile {
    display: block;
    width: 207px;
    text-align: left;
    box-shadow: unset;
  }

  .modalidades.modalidades-mobile .tableCapitalCerto-orangeback,
  .card-new.card-new-mobile .card-new-title {
    padding: 20px;
    width: 100%;
    cursor: default;
  }

  .modalidades.modalidades-mobile .tableCapitalCerto-givenvalue,
  .modalidades.modalidades-mobile .tableCapitalCerto-boldvalue-active {
    padding-top: 0px;
  }

  .modalidades.modalidades-mobile .tableCapitalCerto-subtitle {
    padding-top: 5px;
  }

  .modalidades.modalidades-mobile .tableCapitalCerto-amount,
  .card-new.card-new-mobile .tableCapitalCerto-amount {
    padding: 20px 20px 0px 20px;
  }

  .modalidades.modalidades-mobile .consultar-detalhes {
    text-align: center;
    padding: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-decoration-line: underline;
    color: #6d6e71;
    cursor: pointer;
  }

  .modalidades-mobile-detalhes {
    display: block;
    overflow: scroll;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: white;
    z-index: 10;
    padding-bottom: 20px;
  }

  .modalidades-mobile-detalhes .title-section {
    background: #fff7f2;
    padding: 25px 20px;
  }

  .modalidades-mobile-detalhes .title-section .title-main {
    color: #ff5800;
    font-size: 25px;
    font-family: Raleway;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .modalidades-mobile-detalhes .title-section .title-subtitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #b2b2b2;
  }

  .modalidades-mobile-detalhes .title-section .title-main img {
    cursor: pointer;
    margin-left: auto;
    width: 50px;
    height: 50px;
  }

  .modalidades-mobile-detalhes .montantes-section {
    display: flex;
    padding: 20px 20px;
  }

  .modalidades-mobile-detalhes .montantes-section .tableCapitalCerto-amount {
    width: 50%;
  }

  .modalidades-mobile-detalhes .subscricao-detalhe {
    display: flex;
    margin-bottom: 15px;
  }

  .modalidades-mobile-detalhes .subscricao-detalhe:last-of-type {
    margin-bottom: 0px;
  }

  .modalidades-mobile-detalhes .subscricao-detalhe .label {
    width: 50%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #b2b2b2;
  }

  .modalidades-mobile-detalhes .subscricao-detalhe .value {
    width: 50%;
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #636363;
  }

  .modalidades-mobile-detalhes .subscricao-detalhe .value.acumulado {
    color: #636363;
  }

  .modalidades-mobile-detalhes .subscricao-detalhe .value.data {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #373737;
  }

  .modalidades-mobile-detalhes .serie-mobile {
    padding: 20px 0px;
    border-bottom: 1px solid #dddddd;
  }

  .modalidades-mobile-detalhes .serie-mobile-first-div {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }

  .modalidades-mobile-detalhes .serie-mobile-first-div img {
    margin-left: auto;
  }

  .modalidades-mobile-detalhes .serie-mobile-wrapper {
    padding: 0px 20px;
  }

  .modalidades-mobile-detalhes .new-serie-wrapper {
    padding: 0px 20px;
    margin-top: 20px;
  }

  .card-new.card-new-mobile {
    display: block;
    width: 207px;
    text-align: left;
    box-shadow: unset;
    margin-left: auto;
    margin-right: auto;
    height: unset;
  }

    /* New Protection Subs start*/
    .card-new-sub.card-new-sub-mobile .card-new-sub-title {
        padding: 20px;
        width: 100%;
        cursor: default;
    }

  .subscribe-text {
    width: 100% !important;
  }

  .card-new.card-new-mobile .create-subscription-button {
    margin-top: 30px;
    float: right;
    margin-right: 25px;
    margin-bottom: 25px;
  }

  .card-new.card-new-mobile .create-subscription-button .card-new img {
    margin: 0px;
  }

    .carousel-wrapper.carousel-subscricao-nova-modalidade {
        margin-top: 0px;
    }

  .modalidades-mobile-detalhes .new-series {
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .help-window {
    width: 100%;
  }

  .close-detalhes-protecao-wrapper {
    text-align: -webkit-center;
    margin-top: 20px;
  }

  .close-detalhes-protecao {
    background-color: #ff5800;
    max-width: 300px;
    border-radius: 20px;
    padding: 5px;
    color: white;
    cursor: pointer;
  }

  .card-box {
    display: none;
  }

  .total-entregue {
    background: #f5f7fa;
    padding: 5px 0px;
    align-items: center;
  }

  .card-new-protection.card-new-protection-mobile {
    width: 207px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    margin-bottom: 0px;
  }

  .card-new-protection.card-new-protection-mobile .card-new-protection-content {
    margin: 0px;
    padding: 30px 20px;
  }

  .card-new-protection.card-new-protection-mobile .new-series-subtitle {
    margin-top: 20px;
  }

  .card-new-protection.card-new-protection-mobile
    .create-protection-button-wrapper {
    margin-top: 50px;
  }

  .card-new-protection.card-new-protection-mobile .create-protection-button {
    margin: 0;
    float: right;
    margin-bottom: 25px;
    margin-right: 5px;
  }

    .contact-window {
        width: 100%;
        height: 100%;
        background: white;
        z-index: 10;
        border-radius: 0px;
        align-items: center;
        padding: 50px 10px;        
        position: absolute;
        transform: translate(-50%,-50%);
    }

    .contact-window-2 {
        width: 100%;
        height: 100%;
        background: white;
        z-index: 10;
        border-radius: 0px;
        align-items: center;
        padding: 50px 5px;
        position: absolute;
        transform: translate(-50%,-50%);
    }

  .contact-botton-wrapper {
    width: 100%;
  }

  .contact-botton-wrapper img {
    width: 45% !important;
    max-width: 150px;
  }

  .contact-window-maintext {
    margin-left: auto;
    margin-right: auto;
  }

  .center-div-second-popup {
    width: 100%;
  }

  .contacto-select-boxes-wrapper {
    display: inline-flex;
    margin-bottom: 25px;
    width: -webkit-fill-available;
    margin-left: auto;
    justify-content: center;
  }

  .radiobutton-font {
    width: 45%;
    max-width: 195px;
  }
}
/*Smartphone end*/

/*pop up conta corrente*/

.detalhe-conta-corrente {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #373737;
}

.detalhe-conta-corrente-valor {
  font-family: rawline;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19, 6px;
  color: #373737;
  text-align: right;
}

.conta-corrente-header {
  background-color: #dddddd;
}

.detalhe-conta-corrente-header {
  font-family: rawline;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19, 6px;
  color: #373737;
}

.conta-corrente-grey {
  background-color: #f5f5f5;
}

/*pop up conta corrente mobile*/

.data-conta-corrente-mobile {
  font-family: rawline;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19, 6px;
  color: #373737;
}

.date-current-account-mobile-bar {
  background-color: #dddddd;
}

.operations-current-account-mobile-bar {
  height: 45px;
}

.border {
  border: solid 1px #000;
}

.border-head {
  border-bottom: solid 1px #000;
}

.card-new-sub .card-new-sub-mobile {
    display: block;
    width: 207px;
    text-align: left;
    box-shadow: unset;
    margin-left: auto;
    margin-right: auto;
    height: unset;
}

.card-new-sub .card-new-sub-mobile .card-new-sub-create-subscription-button {
    margin-top: 30px;
    float: right;
    margin-right: 25px;
    margin-bottom: 25px;
}

    .card-new-sub .card-new-sub-mobile .card-new-sub-create-subscription-button .card-new-sub img {
        margin: 0px;
    }



