.center-pie-information {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.center-pie-value {
    font-family: rawline;
    font-weight: bold;
    font-size: 2rem;
    line-height: 140%;
    color: #6D6F71;
}

.center-pie-subtext {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #6D6F71;
    margin-left: 66px;
    margin-right: 66px;
}


/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:576px) {
    .center-pie-information img {
        display: none
    }

    .center-pie-value {
        font-family: rawline;
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
        color: #6D6E71;
    }

    .center-pie-subtext {
        font-family: rawline;
        font-weight: bold;
        font-size: 10px;
        line-height: 140%;
        color: #6D6E71;
        width: 100px;
    }
}

@media(max-width:400px) {
    .center-pie-value {
        font-size: 18px;
    }

    .center-pie-subtext {
        font-size: 6px;
    }
}
