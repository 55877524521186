.quotas-page .ReactTable .rt-thead.-header {
    height: 2px;
    background: black;
    margin-top: 14px;
}

.quotas-page.main .rt-tr-group,
.quotas-page.main .rt-tr-group:last-child {
    background: white;
    border-bottom: 1px solid #DDDDDD;
    min-height: 75px;
}

.title.title-fees {
    width: 100%;
    margin-bottom: 5px;
}

.fees-text {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 160%;
    color: #6D6E71;
}

.help-window.quota-list-popup {
    overflow-y: auto;
    overflow-x: hidden;
}

.quotas-list-popup-content .ReactTable .rt-table {
    overflow: unset;
}

.quotas-list-popup-close {
    float: right;
    margin-right: 50px;
    margin-top: 50px;
    cursor: pointer;
}

.quotas-list-popup-content {
    margin-left: 93px;
    margin-right: 93px;
    margin-top: 130px;
    margin-bottom: 130px;
}

.quotas-list-popup-title {
    font-family: 'rawline', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #373737;
    margin-bottom: 10px;
}

.quotas-list-popup-subtitle {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #6D6F71;
    padding: 0px 50px;
    margin-bottom: 50px;
}

.quotas-list-popup-content .ReactTable .rt-thead.-header {
    height: 2px;
    background: #6D6E71;
    margin-top: 14px;
}

.quota-date {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #373737;
}

.quota-value-text {
    font-family: 'rawline', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    align-items: center;
    text-align: right;
    color: #6D6E71;
}

.quota-value-amount {
    font-family: 'rawline', sans-serif;
    font-weight: bold;
    line-height: 140%;
    align-items: center;
    text-align: right;
    color: #EB2D5B;
    font-size: 15px;
}

.quota-value-wrapper {
    display: flex;
    justify-content: flex-end;
}

.quotas-list-table-header-text {
    font-family: 'rawline', sans-serif;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    text-transform: uppercase;
    color: #373737;
}

.rt-tr-group .rt-td.quota-column {
    min-width: 190px !important;
}

.quotas-list-table-header-amount {
    font-family: 'rawline', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    text-align: right;
    color: #EB2D5B;
}
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:767px) {
    .quotas-list-popup-close {
        margin-right: 75px;
    }

    .quotas-list-popup-content {
        margin-left: 20px;
        margin-right: 20px;
    }

    .rt-tr-group .rt-td.quota-column {
        min-width: 60px !important;
    }

    .quotas-list-popup-subtitle {
        padding: 0px;
    }

    .Table__pagination {
        padding: 20px 0px;
    }
}
/*Smartphone end*/