.notification-banner-container{
    margin-bottom: 25px;
}
.notification-topic{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}


.notification-description{
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    color: #373737;
}

.notification-description-under{
    display: none;
    font-family: Open Sans;
    font-size: 14px;
    line-height: 150%;
    color: #373737;
}

.notification-expanded{
    display: initial;
    align-items: end;
}

    .notification-expanded span{
        white-space: break-spaces !important;
    }

.no-top-notification div{
  margin-bottom: 25px;
  cursor: default;
}
.notification-banner-section{
    width: 754px;
    background: rgba(255,221,177,0.5);
    border-radius: 5px;
    padding: 7px 13px;
    margin-bottom: 15px;
    cursor: pointer;
}

    .notification-banner-section img{
        margin-right: 14px;
    }

    .notification-banner-section span {
        font-family: rawline;
        font-size: 10px;
        line-height: 160%;
        color: #6D6E71;
    }

@media(max-width:767px) {
    .notification-banner-section{
        width: 100%;
        margin-top: 14px;
    }
}
