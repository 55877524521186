.main-page-loading {
  padding-top: 35%;
}

.main-page-loading-sidebar {
  margin-left: 0px;
  width: 194px;
}

.sidebar .loading {
  margin-top: 25%;
  margin-bottom: auto;
  margin-right: auto !important;
  margin-left: auto !important;
}

.MainPage-error {
  margin-top: 25%;
  margin-left: 35%;
}

.loading {
  height: 60px;
  width: 60px;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loading {
  margin-left: 55%;
}

.document-page-document-loading {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

.subscricao-popup-loading {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  left: 46%;
  top: 37%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

@media (min-width: 768px) and (max-width: 880px) {
  .subscricao-popup-loading {
    left: 45%;
    top: 42%;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                              Small Desktop                                                                               *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
Screen = B/w 1025px to 1290px
*/

/*Small Desktop end*/

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .main-page-loading-sidebar {
    display: none;
  }
}
/*Tablet end*/

@media (max-width: 768px) {
  .loading {
    margin-left: auto;
    margin-right: auto;
  }
}
