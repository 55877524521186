body {
  font-family: Arial;
  background: #f5f5f5;
}

/* Style the tab */
.tab {
  width: 754px;
  height: 80px;
  background: #ffffff;
  border-radius: 40px 40px 0px 0px;
  overflow: hidden;
  background-color: #f1f1f1;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

/* Style the buttons inside the tab */
.tab .Tabs__Tab {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  width: 377px;
  height: 80px;
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #595959;
  text-align: center;
  padding-top: 30px;
}

/* Change background color of buttons on hover */
.tab .Tabs__Tab:not(.Tabs__Tab--active):hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab .Tabs__Tab--active {
  background-color: #ffffff;
  color: #ff5800;
  cursor: default;
}

/* Style the tab content */
.tabcontent {
  width: 754px;
  border: 1px;
  padding-top: 10px;
  background: #ffffff;
  border-radius: 0px 0px 40px 40px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.tableheader {
  font-family: rawline;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #595959;
}

.filtros-beneficios {
  display: inline-flex;
  margin-top: 12px;
}

.tableStyle {
  width: 694px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.tablebody {
  font-family: Open Sans;
  font-size: 16px;
  line-height: 160%;
  color: #6d6f71;
}

.cellcolor {
  color: #ff5800;
}

label {
  display: block;
  font: 1rem "Fira Sans", sans-serif;
}

input,
label {
  margin: 0.4rem 0;
}

.divsidebyside {
  float: left;
}

.noDataImg {
  margin-bottom: 12px;
}

.noDataText {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: #b2b2b2;
  text-align: center;
}

.noDataText a {
  color: #b2b2b2;
}

.savedValue-col {
  font-family: rawline;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #ff5800;
  text-align: right;
  padding-right: 10px;
}

.benefitssumlabel {
  font-family: rawline;
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #373737;
  padding-top: 23px;
  padding-right: 15px;
}

.benefitssum {
  font-family: rawline;
  font-weight: bold;
  font-size: 30px;
  color: #ff5800;
}

.image-cropper {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}

.beneficions-badge-div {
  padding-top: 18px;
}

.glossary-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #373737;
}

.benefits-generated-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #ff5800;
  margin-bottom: 20px;
}

.glossary-download-button {
  display: flex;
  align-items: baseline;
}

.glossary-download-button div {
  font-family: rawline;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #373737;
}

.glossary-download-button img {
  margin-left: 8px;
}

.image-cropper-wrapper {
  padding-left: 80px;
}

.ReactTable .rt-thead .tableheader.tableheader-parceiro {
  padding-left: 90px;
}

.parceiro-name {
  font-family: rawline;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: rgb(109, 111, 113);
  padding-top: 15px;
  padding-left: 70px;
}

.beneficios-title-mobile {
  display: block;
  margin-bottom: 95px;
}

.beneficios-title-top {
  font-family: rawline;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;
  color: #373737;
  margin-bottom: 5px;
}

.beneficios-title-bottom {
  font-family: rawline;
  font-weight: bold;
  font-size: 12px;
  line-height: 160%;
  color: #6d6e71;
}

.beneficios-title {
  font-family: rawline;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  color: #ff5800;
  margin-bottom: 20px;
  margin-top: 40px;
}

.divfilter {
  width: 694px;
  height: 94px;
  margin: 30px;
  background: rgba(245, 245, 245, 0.5);
  display: flex;
}

.pie-div {
  z-index: -1;
  position: relative;
}

.pie-div svg {
  overflow: visible;
}

.pie-label {
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 70px;
  width: 90px;
  height: 90px;
}

.toggle-text-active {
  font-family: rawline;
  font-weight: bold;
  font-size: 14px;
  color: #ff5800 !important;
  margin-right: 10px;
}

.toggle-text-inactive {
  font-family: rawline;
  font-weight: bold;
  font-size: 14px;
  color: #b2b2b2 !important;
  margin-right: 10px;
}

/*Foi alterado para display none porque não está funcional*/
.filter-dropdown {
  width: 50%;
  display: flex;
  margin: auto 0 auto 30px;
}

.noDataText a {
  color: #ff5800;
}

/*Dropdown*/
.dropdown-benefits-button {
  font-family: rawline;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #ff5800;
  width: 225px;
  height: 45px;
  display: inline-block;
  padding: 0px 20px 0px 20px;
  border: 0px;
  outline: 0px;
  border-bottom: 1.5px solid #b2b2b2;
  cursor: pointer;
  background: url("../../images/expand.png") no-repeat right;
  background-position-x: 200px;
  background-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-position-y: 14px;
  text-align: left;
  border-radius: 0px;
}

.dropdown-benefits-button:hover {
  color: #ff5800;
  background-color: rgba(255, 88, 0, 0.1);
  border-color: rgba(255, 88, 0, 0.1);
}

.dropdown-benefits-button:focus {
  background-color: rgba(255, 88, 0, 0.1) !important;
  color: #ff5800 !important;
  border-bottom: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.dropdown-benefits-button:active {
  background-color: rgba(255, 88, 0, 0.1) !important;
  color: #ff5800 !important;
  border-bottom: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.filter-dropdown-graph {
  margin-right: 30px;
}

.filter-graph-mobile {
  display: none;
}

.dropdown-benefits-filter-graph {
  font-family: rawline;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
  color: #ff5800;
  height: 45px;
  display: inline-block;
  padding: 0px 40px 0px 20px;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  background: url("../../images/expand.png") no-repeat right;
  background-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-position-y: 14px;
  text-align: left;
  border-radius: 0px;
}

.dropdown-benefits-filter-graph:hover {
  color: #ff5800;
  background-color: rgba(255, 88, 0, 0.1);
  border-color: rgba(255, 88, 0, 0.1);
}

.dropdown-benefits-filter-graph:focus {
  background-color: rgba(255, 88, 0, 0.1) !important;
  color: #ff5800 !important;
  border-bottom: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.dropdown-benefits-filter-graph:active {
  background-color: rgba(255, 88, 0, 0.1) !important;
  color: #ff5800 !important;
  border-bottom: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.dropdown-benefits-menu {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  border-top: none;
}

.dropdown-benefits-option {
  width: 224px;
  font-family: rawline;
  font-weight: bold;
  font-size: 12px;
  color: #595959;
  padding: 17px 19px;
}

.dropdown-benefits-option:active {
  background: rgba(255, 88, 0, 0.1);
  color: #595959;
  text-decoration: none;
}

.dropdown-benefits-option:focus {
  background: rgba(255, 88, 0, 0.1);
  outline: 0;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: initial !important;
}

.selected {
  background: rgba(255, 88, 0, 0.1);
}

.beneficios-title-div {
  width: 754px;
  display: inline-flex;
  height: 40px;
  margin-bottom: 60px;
}

.beneficios-toggle-div {
  width: 50%;
  float: right;
  margin-top: 10px;
  text-align: right;
  margin-right: 15px;
  display: contents;
}

.inactive-tooltip {
  position: relative;
  display: inline-block;
}

.inactive-tooltip-text {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.inactive-tooltip-text:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.inactive-tooltip .inactive-tooltip-text {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
}

.inactive-tooltip:hover .inactive-tooltip-text {
  visibility: visible;
  opacity: 1;
}
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Tablet                                                                                  *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Tablets, Ipads (portrait)
Screen = B/w 768px to 1024px
*/
/*Necessario porque no tamanho de 768 até 880 não há espaço para tudo o que existe nos ecrãs*/
@media (min-width: 768px) and (max-width: 880px) {
  .tabcontent,
  .tab {
    width: 100%;
  }

  .divfilter {
    width: 100%;
    border-right: 30px solid white;
    border-left: 30px solid white;
    margin: 30px 0px;
  }

  .tableStyle {
    width: 100%;
    margin-left: 0px;
  }

  .tab .Tabs__Tab {
    width: 330px;
  }

  .benefitssumlabel {
    padding-left: 0px;
  }

  .beneficions-badge-div {
    padding-left: 380px;
  }

  .beneficios-title-div {
    width: 100%;
  }
}

/*Tablet end*/
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media (max-width: 767px) {
  .tab {
    width: 100%;
    border-radius: 0px;
  }

  .filtros-beneficios {
    margin-right: 15px;
  }

  .filter-graph-mobile {
    display: flex;
    text-align: right;
    margin-bottom: 40px;
  }

  .filter-graph {
    display: none;
  }

  .filter-dropdown-graph {
    display: block;
    margin-left: auto !important;
    margin-right: 15px !important;
    margin-top: 0px !important;
  }

  .inactive-tooltip-text {
    bottom: -25%;
    left: -180%;
    margin-left: -60px;
  }

  .inactive-tooltip-text:after {
    content: "";
    position: absolute;
    top: 38%;
    left: initial;
    right: -7%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(-90deg);
  }

  .tab .Tabs__Tab {
    width: 50%;
  }

  .tabcontent {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0px;
  }

  .divfilter {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .tableStyle {
    width: 100%;
    margin: 0px;
    padding-bottom: 20px;
  }

  .rt-th,
  .rt-tr-group .rt-td {
    width: 33% !important;
  }

  .image-cropper-wrapper {
    padding-left: 0px;
  }

  .ReactTable .rt-thead .tableheader.tableheader-parceiro {
    padding-left: 0px;
  }

  .beneficions-badge-div,
  .beneficios-title {
    display: none;
  }

  .benefitssumlabel {
    padding-left: 0px;
    float: right;
  }

  .beneficios-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .beneficios-container .main {
    padding-bottom: 0px;
  }

  .beneficios-title-mobile {
    display: block;
    margin-left: 15px;
  }

  .beneficios-toggle-div {
    margin-top: 0px;
  }

  .beneficios-title-top {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    color: #373737;
  }

  .beneficios-title-bottom {
    display: none;
  }

  .badge-wrapper {
    display: none;
  }

  .beneficios-title-div {
    width: 100%;
    margin-bottom: 0px;
  }
}
/*Title lower font size*/
@media (max-width: 450px) {
  .beneficios-title-top {
    font-size: 16px;
  }

  .beneficios-toggle-div {
    margin-top: 0px;
  }

  .filtros-beneficios {
    margin-top: 0px;
  }

  .filter-graph-mobile {
    margin-top: -15px;
  }
}
/*Title end*/
/*Smartphone end*/
