
.Title {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: #373737;
    margin-bottom: 64px;
}

.Associate-Fields {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 160%;
}

    .Associate-Fields main {
        width: 100%;
    }

    .Associate-Fields form {
        padding-bottom: 3%;
    }

.Associate-Field label {
    font-family: rawline;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #FF5800 !important;
    font-weight: 900;
    margin-bottom: 0px;
}

.Associate-Fields label {
    font-family: Open Sans;;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: #6D6F71;
    font-weight: 900;
    margin-bottom: 0px;
}

.Associate-Fields input {
    width: 80%;
    border: none;
    background: #fff /*rgba(255,255,255, 0.4)*/;
    padding: 8px 9px 6px 10px;
}

    .Associate-Fields input[type=checkbox] {
        width: 5%;
        border: none;
        background: #fff /*rgba(255,255,255, 0.4)*/;
        padding: 8px 9px 6px 10px;
        accent-color: red;
    }

    .Associate-Fields input:focus,
    .Associate-Same-Line-Fields input:focus {
        outline: none;
    }


.Associate-Same-Line-Fields {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 160%;
    color: #6D6F71;
    display: flex;
}

    .Associate-Same-Line-Fields form {
        /* padding-bottom: 3%; */
        display: inline-block;
    }

    .Associate-Same-Line-Fields label {
        font-family: rawline;
        font-weight: bold;
        font-size: 12px;
        line-height: 140%;
        color: #6D6F71;
        margin-bottom: 0px;
        text-align: left;
        width: 160%;
        margin: 0 auto;
    }

    .Associate-Same-Line-Fields input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none; 
        position: inherit;
    }

    .Associate-Same-Line-Fields input[type='image'] {
        border-bottom: 0;
    }

.right-form {
    margin-left: 60px;
}

.Associate-Fields button {
    border: 2px solid #FF5800;
    box-sizing: border-box;
    border-radius: 50px;
    height: 35px;
    width: 150px;
    background: #FF5800;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    float: right;
}

.Associate-Fields button:disabled, .Associate-Fields button[disabled] {
    border: 2px solid #cccccc;
    box-sizing: border-box;
    border-radius: 50px;
    height: 35px;
    width: 150px;
    background: #cccccc;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    float: right;
}

/*checkbox orange The container */
label.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default checkbox */
    label.container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        border: 1px;
        font-weight: bold;
        font-size: 14px;
    }

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee !important;
    border: 1px;
}

/* On mouse-over, add a grey background color */
label.container:hover input ~ .checkmark {
    background-color: #ccc;
    border: 1px;
}

/* When the checkbox is checked, add a blue background */
label.container input:checked ~ .checkmark {
    background-color: #FF5800 !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
label.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
label.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}




/*Botão de adicionar NIF*/
.add-nif-btn {
    display: flex;
    align-items: baseline;
    border: 1px solid #ff5800;
    box-sizing: border-box;
    border-radius: 50px;
    font-family: rawline;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    align-items: center;
    color: #ff5800;
    height: 30px;
    width: 180px;
    background-color: #fff;
}

.add-nif-btn img {
    margin-left: 8px;
}

#nif{
    border: none;
}