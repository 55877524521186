.no-mail-div {
    text-align: center;
    margin-top: 30%;
}

.no-mail-img {
    width:100px;
}

.no-mail-text {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #373737;
}

.mail-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
}

.mail-name {
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.mail-list-wrapper {
    margin-top: 60px;
    background: #FFFFFF;
    border-radius: 40px;
}

    .mail-list-wrapper .rt-thead.-header {
        display: none;
    }

    .mail-list-wrapper .mail-list {
        padding: 30px 28px 0px 28px;
    }

    .mail-list-wrapper .pagination-bottom {
        margin-top: 30px;
        margin-bottom: 57px;
    }

    .mail-list-wrapper .ReactTable .rt-tbody .rt-tr-group {
        border-bottom:0px;
    }

    .title-bar.title-bar-mail .title {
        width: 100%;
    }

    .title-bar.title-bar-mail {
        height:unset;
        overflow:unset;
    }

    .mail-list-sort {
        padding-top: 38px;
    }

    .mail-list-sort .filter-dropdown {
        min-width: 300px;
    }
/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Smartphone                                                                              *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
/* 
Device = Smartphones
Screen = B/w until 767px
*/
@media(max-width:767px) {
    .mail-list-sort{
        margin: 20px;
        background: rgba(245,245,245,0.5);
        padding:1px;
    }

    .mail-list-sort .filter-dropdown {
            display: flex;
        } 
    
    .mail-list-wrapper{
        margin-right: -15px;
        margin-left: -15px;
        border-radius: 0px;
        padding-top: 1px;
    }
    .mail-list-wrapper .mail-list{
        padding-top:0px;
    }
    .mail-date-wrapper{
        display:none;
    }
    /*Smaller than 500*/
    @media(max-width:500px) {
        .mail-list-sort .filter-dropdown {
            display: block;
            min-width:unset;
        } 
    }
    /*Smaller than 420*/
    @media(max-width:420px) {
        .mail-date-wrapper {
            display:none;
        }
    }
    /*Smaller that 420 end*/
}
/*Smartphone end*/





