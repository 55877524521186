
.Title {
    font-family: rawline;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    color: #373737;
    margin-bottom: 74px;
}

.User-Fields {
    font-family: Open Sans;
    font-size: 16px;
    line-height: 160%;
    color: #6D6F71;
}
    .User-Fields main {
        width: 100%;
    }

    .User-Fields form {
        padding-bottom: 3%;
    }

    .User-Fields label {
        font-family: rawline;
        font-weight: bold;
        font-size: 12px;
        line-height: 140%;
        color: #6D6F71;
        margin-bottom: 0px;
    }

    .User-Fields input {
        width: 100%;
        border: none;
        background: rgba(229,229,229, 0.4);
        padding: 8px 9px 6px 10px;
    }

        .User-Fields input:focus,
        .Same-Line-Fields input:focus {
            outline: none;
        }
        
        .Same-Line-Fields {
            font-family: Open Sans;
            font-size: 16px;
            line-height: 160%;
            color: #6D6F71;
            display: flex;
        }

    .Same-Line-Fields form {
        padding-bottom: 3%;
        display: inline-block;
        width:50%;
    }

    .Same-Line-Fields label {
        font-family: rawline;
        font-weight: bold;
        font-size: 12px;
        line-height: 140%;
        color: #6D6F71;
        margin-bottom: 0px;
    }

    .Same-Line-Fields input {
        width: 100%;
        border: none;
        border-radius: 5px;
        background: rgba(229,229,229, 0.4);
        padding: 8px 9px 6px 10px;
    }

.right-form {
    margin-left: 60px;
}

/*NEW CSS TABS*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


/* Tab Container */
.Tabs {
    width: 80%;
    height: auto;
    min-height: 400px;
    background: #053742;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 1rem;
    color: #e8f0f2;
    /*border-radius: 2rem;*/


    @media (max-width: 768px) {
        padding: 2rem 0;
    }
}

/* Tab Navigation */
ul.Same-Line-Fields {
    /*width: 60%;*/
    width: 100%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: rawline;
    font-weight: bold;


    @media (max-width: 768px) {
        width: 90%;
    }
}

    ul.Same-Line-Fields li {
        width: 50%;
        padding: 1rem;
        list-style: none;
        text-align: center;
        cursor: pointer;
        transition: all /*0.7s*/;
        border-bottom: 4px solid #E5E5E5;
    }

        ul.Same-Line-Fields li.active {
            background: #FDFFFF;
            border-bottom: 4px solid #dd5d1e;
            color: black;
        }