.container-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.popup-loading-title {
    text-align: center;
}

.popup-loading-title h3 {
        color: #ff5800;
        /*font-family: rawline;
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;*/
}



.loading-popup {

    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}



