.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 16px;
}

.Table__pagination {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
}

.Table__pageButton {
    color: #939393;
    font-size: 12px;
    font-family: rawline;
    font-style: normal;
    font-weight: bold;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 26px;
    height: 26px;
    margin-left: 9px;
}

    .Table__pageButton:disabled {
        cursor: not-allowed;
        color: gray;
    }

.Table__pageButton--active {
    color: #FFFFFF;
    font-weight: bold;
    font-family: rawline;
    font-style: normal;
    font-size: 12px;
    background: #FF5800;
    border-radius: 100%;
}