/*body{
	background: #E5E5E5;
}*/

.nav {
    position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #FFFFFF;
}

.nav img{
   position: absolute;
  left: 10%;
  right: 0%;
  top: 5%;
  bottom: 0%;
  background: #FFFFFF;
}

#continue{
  margin-bottom: 20%;
}

main .imgs {
  position: fixed;
  width: 705px;
  height: 810px;
  left: 0px;
  top: 90px;

  
  mix-blend-mode: multiply;
}



main .right .text{
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;	  
}

main .right .text .title{
  position: absolute;
  left: 57.85%;
  right: 23.82%;
  top: 17.22%;
  bottom: 77.22%;

  /* H1 - 42px Raleway Bold */

  font-size: 42px;
  line-height: 49px;
  /* identical to box height */

  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Orange */

  color: #FF5800;
}

main .right .text .subtitle{

  /* Faça a sua adesão de forma simples para aproveitar a sua Associação ao máximo. */

  position: absolute;
  left: 57.85%;
  right: 14.93%;
  top: 25.11%;
  bottom: 69.22%;

  /* H5 - 18px Raleway Bold */

  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Grey Ultra Dark */

  color: #373737;

}

main .right .form {
    position: absolute;
  right: 0%;
  top:  45%;
  bottom: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  
  
}

.intro{
  display: none;
}

.error.itemLevel{
  display: none;
}

.helpLink.tiny{
  display: none;
}
.attr{
  line-height: 250%;
}


.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.column-esquerda {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
} 


.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background: white;
  z-index: 50;
}

.toolbar_navigation{
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar_logo img {
    margin: 0 147px;
}

.toolbar_navigation-items{
  padding-left: 99px;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: #838383;
  list-style-type: none;
}

.toolbar_navigation-items li{
  padding: 0 0.5rem;
  text-decoration: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}


#titulo-maintenance{
	font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: bold;
font-size: 30px;
line-height: 120%;
/* or 36px */
text-align: center;
font-feature-settings: 'pnum' on, 'lnum' on;
/* Grey Ultra Dark */
color: #373737;
margin-bottom: 56px;
}

#MyDivsuccess {
    height: 18em;
    margin: 0 auto;
    text-align: center;
    top: 90px;
    position: relative;
}

#MainContainer {
    position: unset;
    top: 0;
    z-index: -1;
    min-height: 50%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .toolbar {
        height: 60px;
    }

    .toolbar_navigation {
        margin: 0px;
        padding: 0px 15px;
    }

    .toolbar_logo img {
        margin: 0px;
        width: 120px;
        height: auto;
    }
}

#maintenance {
    max-width: 640px;
    margin-bottom: 51px;
    width: 90vw;
}

.contact {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    /* or 26px */
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    /* Grey Ultra Dark */
    color: #373737;
}

.contact-number {
    font-size: 28px;
    font-weight: bold;
}

.contact-availability {
    margin-top: 25px;
}

.with-background-image {
    padding-top: 90px
}

/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Fixes of background                                                                             *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
*/

@media(max-width:1200px) {
    .with-background-image {
        padding-top: 60px
    }
}


/****************************************************************************************************************************************************************************
 *                                                                                                                                                                          *
 *                                                                                  Fixes of text size                                                                             *
 *                                                                                                                                                                          *
/****************************************************************************************************************************************************************************
*/
@media(max-width:780px) {
    #titulo-maintenance {
        font-size: 25px;
    }
}
@media(max-width:640px) {
    #titulo-maintenance {
        font-size: 20px;
    }

    .contact {
        font-size: 16px;
    }

    .contact-number {
        font-size: 20px;
    }
}
/*Smartphone end*/