.quota-atraso-wrapper {
    display: flex;
    align-items: center;
    background: #FFF5E8;
    border-radius: 5px;
}

.quota-atraso-img img {
    width: 26px;
    margin: 0px 14px;
}

.quota-atraso-texto {
    padding: 12px 14px 12px 0px;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 160%;
    color: #6D6E71;
    text-align: initial;
}

.quota-atraso-popup-trigger {
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
    color: #6D6E71;
}

    .quota-atraso-popup-trigger.active {
        color: #6D6E71;
    }